import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
  Autocomplete,
  Tabs,
  Tab,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  RecordVoiceOver,
  Redo,
  Replay,
  Search,
  Send,
  StopCircle,
  Troubleshoot,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllBots from 'redux/actions/Common/getAllBots';
import RichTextEditor from 'components/RichTextEditor';

import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getAllPrompts from 'redux/actions/Common/getAllPrompts';
import createNewBot from 'redux/actions/Common/createNewBot';
import updateBot from 'redux/actions/Common/updateBot';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import assignBot from 'redux/actions/Common/assignBot';
import createNewConversation from 'redux/actions/Common/createNewConversation';
import deleteBot from 'redux/actions/Common/deleteBot';
import createPracticeCallLink from 'redux/actions/Common/createPracticeCallLink';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import { capitalizeText } from 'utils';
import getPromptPreview from 'redux/actions/Common/getPromptPreview';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import getPineconeIndices from 'redux/actions/Common/getPineconeIndices';
import * as yup from 'yup';
import { useFormik } from 'formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const validationSchemaSearch = yup.object().nullable();

const Bots = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    bots,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allBots);

  const allOrganizations = useSelector((state) => state.allOrganizations);

  const { prompts } = useSelector((state) => state.allPrompts);
  const promptSelectRef = useRef(null);
  const drawerContentRef = useRef(null);

  const [selectedBot, setSelectedBot] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [botTitle, setBotTitle] = useState('');
  const [description, setDescription] = useState('');
  const [multilingual, setMultilingual] = useState(false);
  const [botType, setBotType] = useState('standard');
  const [botAudio, setBotAudio] = useState('David');
  const [botsVoices, setBotsVoices] = useState([]);
  const [indianBotsVoices, setIndianBotsVoices] = useState([]);
  const [published, setPublished] = useState(false);
  const [visibility, setVisibility] = useState('published_for_specific_user');
  const [draft, setDraft] = useState('true');
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [promptSections, setPromptSections] = useState([]);
  const [placeholder, setPlaceholder] = useState({});
  const [personalities, setPersonalities] = useState([]);
  const [promptsConfigurable, setPromptsConfigurable] = useState(true);

  const [assignDialog, setAssignDialog] = useState('');
  const [showCopyPublicPracticeLink, setShowCopyPublicPracticeLink] = useState(
    '',
  );
  const [email, setEmail] = useState('');
  const [createConversationDialog, setCreateConversationDialog] = useState('');

  const [conversationDescription, setConversationDescription] = useState('');
  const [conversationTitle, setConversationTitle] = useState('');
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const [error, setError] = useState('');
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [competitors, setCompetitors] = useState('');

  const [salesPersonName, setSalesPersonName] = useState('');
  const [createError, setCreateError] = useState('');

  const [vertical, setVertical] = useState('');
  const [designation, setDesignation] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [sellerCompanyName, setSellerCompanyName] = useState('');
  const [transcriptionType, setTranscriptionType] = useState('webspeech');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [showConfirmDuplicateDialog, setShowConfirmDuplicateDialog] = useState(
    null,
  );
  const [link, setLink] = useState('');
  const [creds, setCreds] = useState('');

  const [countries, setCountries] = useState([]);
  const [showPromptPreview, setShowPromptPreview] = useState('');
  const [showPromptPreviewFor, setShowPromptPreviewFor] = useState('');

  const [pineconeIndices, setPineconeIndices] = useState([]);
  const [ragIndexName, setRagIndexName] = useState('');
  const [defaultResponse, setDefaultResponse] = useState('');

  const [vapiEnabled, setVapiEnabled] = useState(false);
  const [orgType, setOrgType] = useState('n_org');

  const [gptConfig, setGptConfig] = useState({
    frequencyPenalty: 0,
    maxTokens: 256,
    presencePenalty: 0,
    temperature: 0,
    topP: 1,
  });
  const initialValuesSearch = {
    searchKeyword: '',
  };

  const handleAddSectionClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setCountries((prev) => [...prev, e.target.value]);
    } else {
      setCountries((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const resetFields = () => {
    setBotTitle('');
    setDescription('');
    setDefaultResponse('');
    setBotType('standard');
    setMultilingual(false);
    setBotAudio('David');
    setBotsVoices([]);
    setRagIndexName('');
    setIndianBotsVoices([]);
    // setPublished(false);
    setVisibility('published_for_all');
    setDraft(true);
    setSelectedPrompt(null);
    setPromptSections([]);
    setPlaceholder({});
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        bots &&
        bots.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllBots(15, lastTime, () => {}));
    }
  };

  const handleOrgListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !allOrganizations?.fetching &&
        !allOrganizations?.fetchingPagination &&
        allOrganizations?.organizations &&
        allOrganizations?.organizations?.length % 15 === 0 &&
        allOrganizations?.emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllOrganizations(
          formikSearch?.values?.searchKeyword
            ? [formikSearch?.values?.searchKeyword]
            : [],
          true,
          orgType,
          15,
          allOrganizations?.lastTime,
          () => {},
        ),
      );
    }
  };

  const onSearch = (values) => {
    console.log(values);
    if (values?.searchKeyword?.trim()) {
      dispatch(
        getAllOrganizations(
          [values?.searchKeyword],
          true,
          orgType,
          15,
          null,
          () => {},
        ),
      );
    } else {
      dispatch(getAllOrganizations([], true, orgType, 15, null, () => {}));
    }
  };

  const formikSearch = useFormik({
    initialValues: initialValuesSearch,
    validationSchema: validationSchemaSearch,
    onSubmit: onSearch,
  });

  useEffect(() => {
    if (selectedBot && selectedBot !== 'create') {
      const bot = bots?.find((bot) => bot?.id === selectedBot);
      setBotTitle(bot?.title);
      setDescription(bot?.description);
      setMultilingual(bot?.multilingual);
      setDefaultResponse(bot?.defaultResponse || '');
      setBotType(bot?.botType);
      setRagIndexName(bot?.ragIndexName);
      setBotAudio(bot?.botAudio);
      setVisibility(bot?.visibility);
      setDraft(bot?.draft ? 'true' : 'false');
      setPromptSections(bot?.promptSections);
      setCountries(bot?.countries || []);
      setSelectedPrompt(bot?.promptId);
      setVapiEnabled(bot?.vapiEnabled);
    }
  }, [selectedBot]);

  useEffect(() => {
    if (
      selectedBot === 'create' ||
      selectedPrompt !== bots?.find((bot) => bot?.id === selectedBot)?.promptId
    ) {
      if (selectedPrompt && selectedPrompt !== 'create') {
        setPromptSections(
          prompts?.find((pr) => pr?.id === selectedPrompt)?.promptSections,
        );
      } else {
        setPromptSections([]);
      }
    } else {
      setPromptSections(
        bots?.find((bot) => bot?.id === selectedBot)?.promptSections,
      );
    }
  }, [selectedPrompt, selectedBot]);

  useEffect(() => {
    if (selectedBot) {
      dispatch(
        getDefaultPrompt((prompt) => {
          console.log(prompt);
          setBotsVoices(
            prompt?.botAudio
              ?.filter((botAudio) => botAudio?.country === 'united states')
              ?.map(
                (nm) =>
                  nm[
                    Object.keys(nm || {})?.filter(
                      (prop) => prop === 'name',
                    )?.[0]
                  ],
              ),
          );
          setIndianBotsVoices(
            prompt?.botAudio
              ?.filter((botAudio) => botAudio?.country === 'india')
              ?.map(
                (nm) =>
                  nm[
                    Object.keys(nm || {})?.filter(
                      (prop) => prop === 'name',
                    )?.[0]
                  ],
              ),
          );
          setPersonalities(prompt?.persona || []);
        }),
      );
      dispatch(getAllPrompts('conversation', 50, null, () => {}));
    }
  }, [selectedBot]);

  useEffect(() => {
    if (assignDialog) {
      dispatch(getAllOrganizations([], true, orgType, 15, null, () => {}));
    }
    formikSearch.resetForm();
  }, [assignDialog, orgType]);

  useEffect(() => {
    if (createConversationDialog) {
      dispatch(
        getDefaultPrompt((data) => {
          console.log(data);
          setVerticalOptions(data?.verticals);
          setDesignationOptions(data?.designations);
        }),
      );
    }
  }, [createConversationDialog]);

  useEffect(() => {
    if (botType === 'digital_twin') {
      dispatch(
        getPineconeIndices((indices) => {
          setPineconeIndices(indices || []);
        }),
      );
    }
  }, [botType]);

  useEffect(() => {
    dispatch(getAllBots(15, null, () => {}));
  }, []);

  console.log('promptSections', promptSections);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
        >
          <TableContainer
            component={Paper}
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
            onScroll={handleListScroll}
          >
            <Table
              sx={{ minWidth: 750 }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      TITLE
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      DESCRIPTION
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      COUNTRY
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Draft
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Visibility
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      BOT AUDIO
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                      align="center"
                    >
                      DATE
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      ACTIONS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bots.map((bot, i) => (
                  <TableRow
                    key={i}
                    // sx={{
                    //   '&:last-child td, &:last-child th': {
                    //     border: 0,
                    //   },
                    // }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant={'subtitle2'} fontWeight={700}>
                        {bot?.title}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip
                        title={
                          <Box p={1}>
                            <Typography>{bot?.description}</Typography>
                          </Box>
                        }
                        arrow
                        placement="bottom"
                      >
                        <Box
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          sx={{
                            maxWidth: 150,
                            fontSize: 14,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {bot?.description}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant={'subtitle2'}>
                        {bot?.countries
                          ?.map((country) => capitalizeText(country))
                          ?.join(',')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant={'subtitle2'}>
                        {bot?.draft ? 'true' : 'false'}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant={'subtitle2'}>
                        {bot?.visibility === 'published_for_all'
                          ? 'Public'
                          : 'Private'}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant={'subtitle2'}>
                        {bot?.botAudio}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        // fontWeight={700}
                        // sx={{
                        //   color: theme.palette.success.main,
                        // }}
                      >
                        {moment(bot?.createdOn).format('DD MMMM YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <Tooltip title={'Preview Prompt'} placement="top" arrow>
                          {/* {analysisInProgress.includes(prompt?.id) ? (
                                    <CircularProgress size={20} />
                                  ) : ( */}
                          <IconButton
                            onClick={() => {
                              dispatch(
                                getPromptPreview(
                                  {
                                    description: bot?.description,
                                    promptSections: bot?.promptSections,
                                    promptType: 'conversation',
                                    title: bot?.title,
                                  },
                                  (promptPreview) => {
                                    console.log(promptPreview);
                                    setShowPromptPreviewFor(bot?.title);
                                    setShowPromptPreview(promptPreview);
                                  },
                                ),
                              );
                              // setSelectedPrompt(prompt?.id);
                            }}
                          >
                            <Assignment color="default" />
                          </IconButton>
                          {/* )} */}
                        </Tooltip>
                        <Tooltip title={'Edit'} placement="top" arrow>
                          {/* {analysisInProgress.includes(bot?.id) ? (
                                    <CircularProgress size={20} />
                                  ) : ( */}
                          <IconButton
                            onClick={() => {
                              setSelectedBot(bot?.id);
                            }}
                          >
                            <Edit color={'default'} />
                          </IconButton>
                          {/* )} */}
                        </Tooltip>
                        <Tooltip
                          title={
                            bot?.draft
                              ? 'Bot should be published to copy public practice link'
                              : 'Copy Public Practice Link'
                          }
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={() => {
                              if (bot?.draft) {
                                return;
                              } else {
                                setShowCopyPublicPracticeLink(bot?.id);
                              }
                            }}
                          >
                            <Send color={'default'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Assign Bot'} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              setAssignDialog(bot?.id);
                            }}
                          >
                            <Group color={'default'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={'Create Conversation'}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={() => {
                              setCreateConversationDialog(bot?.id);
                            }}
                          >
                            <RecordVoiceOver color={'default'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Duplicate Bot'} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              setShowConfirmDuplicateDialog(bot?.id);
                            }}
                          >
                            <ContentCopy color={'default'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete'} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              setShowConfirmDeleteDialog(bot?.id);
                            }}
                          >
                            <Delete color={'default'} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    variant="footer"
                    colSpan={6}
                    sx={{
                      padding: 0,
                    }}
                  >
                    {fetchingPagination ? (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          color="primary"
                          onClick={handleListScroll}
                          // className="searchSettings"
                          disabled
                          // fullWidth
                        >
                          <Typography color="textPrimary">
                            Loading more bots ...{' '}
                          </Typography>
                          <CircularProgress size={20} />
                        </Button>
                      </Box>
                    ) : emptyList === false && bots.length >= 10 ? (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          // fullWidth
                          color="primary"
                          onClick={(e) => handleListScroll(e, true)}
                          // className="searchSettings"
                        >
                          Load More
                        </Button>
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {!selectedBot ? (
            <Tooltip arrow placement="top" title="Create New">
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 30, right: 30 }}
                onClick={() => setSelectedBot('create')}
              >
                <Add />
              </Fab>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(selectedBot)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedBot('');
                resetFields();
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Create Bot</Typography>
          </Box>
          <Box
            ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Grid container spacing={1} p={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Bot Name
                </Typography>
                <TextField
                  placeholder="Title"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="title"
                  value={botTitle}
                  onChange={(e) => setBotTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Description
                </Typography>
                <TextField
                  placeholder="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Vapi Enabled
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={vapiEnabled}
                    onChange={(e) => setVapiEnabled(e.target.value)}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={'No'}
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={'Yes'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Multilingual
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={multilingual}
                    onChange={(e) => setMultilingual(e.target.value)}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={'Non-multilingual'}
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={'Multilingual'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Bot Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={botType}
                    onChange={(e) => setBotType(e.target.value)}
                  >
                    <FormControlLabel
                      value={'standard'}
                      control={<Radio />}
                      label={'Standard'}
                    />
                    <FormControlLabel
                      value={'digital_twin'}
                      control={<Radio />}
                      label={'Digital Twin'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {botType === 'digital_twin' ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Default Response
                    </Typography>
                    <TextField
                      placeholder="Default Response"
                      multiline
                      rows={2}
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="defaultResponse"
                      value={defaultResponse}
                      onChange={(e) => setDefaultResponse(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      sx={{ minWidth: 1 }}
                      size="small"
                    >
                      <FormLabel id="ragIndex">Pinecone Index</FormLabel>
                      <Select
                        labelId="ragindex-select-label"
                        value={ragIndexName || ''}
                        onChange={(e) => {
                          setRagIndexName(e.target.value);
                        }}
                      >
                        {pineconeIndices && pineconeIndices.length > 0
                          ? pineconeIndices.map((indice) => (
                              <MenuItem value={indice?.name}>
                                {indice.name}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                    Select Countries
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      checked={countries?.includes('united states')}
                      control={
                        <Checkbox
                          size="small"
                          value={'united states'}
                          name="countries"
                        />
                      }
                      label="United States"
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControlLabel
                      checked={countries?.includes('india')}
                      control={
                        <Checkbox
                          size="small"
                          value={'india'}
                          name="countries"
                        />
                      }
                      label="India"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Bot Voices
                  </FormLabel>
                  <Typography variant="caption" color={'text.secondary'}>
                    US Voices
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group1"
                    value={botAudio}
                    onChange={(e) => setBotAudio(e.target.value)}
                  >
                    {botsVoices?.map((bv) => (
                      <FormControlLabel
                        value={bv}
                        control={<Radio />}
                        label={bv}
                      />
                    ))}
                  </RadioGroup>
                  <Typography variant="caption" color={'text.secondary'}>
                    Indian Voices
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group2"
                    value={botAudio}
                    onChange={(e) => setBotAudio(e.target.value)}
                  >
                    {indianBotsVoices?.map((bv) => (
                      <FormControlLabel
                        value={bv}
                        control={<Radio />}
                        label={bv}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Published
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={draft}
                    onChange={(e) => {
                      setDraft(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={'true'}
                      control={<Radio />}
                      label={'Draft'}
                    />
                    <FormControlLabel
                      value={'false'}
                      control={<Radio />}
                      label={'Published'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Visibility
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  >
                    <FormControlLabel
                      value={'published_for_all'}
                      control={<Radio />}
                      label={'Public'}
                    />
                    <FormControlLabel
                      value={'published_for_specific_user'}
                      control={<Radio />}
                      label={'Private'}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* {selectedBot === 'create' ? ( */}
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: 1 }}
                  size="small"
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Prompt
                  </FormLabel>
                  <Select
                    ref={promptSelectRef}
                    labelId="prompt-select-label"
                    // label="Select Prompt"
                    value={selectedPrompt || ''}
                    onChange={(e) => {
                      setSelectedPrompt(e.target.value);
                    }}
                  >
                    <MenuItem value="Select Prompt">
                      <em>Select Prompt</em>
                    </MenuItem>
                    {prompts && prompts.length > 0
                      ? prompts.map((prompt) => (
                          <MenuItem value={prompt?.id}>{prompt.title}</MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              {/* ) : null} */}
              <Grid item xs={12}>
                {(selectedPrompt && selectedPrompt !== 'Select Prompt') ||
                (selectedBot && selectedBot !== 'create') ? (
                  <Box>
                    <Box>
                      <Grid container spacing={2}>
                        {promptSections?.map((ps) => (
                          <Grid item xs={12} key={ps?.type}>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                            >
                              <Box
                                display={'flex'}
                                // alignItems={'center'}
                                flexDirection={'column'}
                                // justifyContent={'space-between'}
                                gap={1}
                              >
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  sx={{
                                    background: '#f1f1f1',
                                    padding: '8px 16px',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color={'text.secondary'}
                                    sx={{
                                      background: '#f1f1f1',
                                      padding: 2,
                                    }}
                                  >
                                    {ps?.type}
                                  </Typography>
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      setPromptSections((prev) =>
                                        prev.filter(
                                          (prevps) => prevps?.type !== ps?.type,
                                        ),
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                                <FormControl>
                                  <Select
                                    size="small"
                                    labelId="country-select-label"
                                    id="country-select"
                                    name="country"
                                    // disabled={true}
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) => {
                                      const updatedPromptSections = promptSections?.map(
                                        (ups) => {
                                          if (ups?.type === ps?.type) {
                                            return {
                                              ...ups,
                                              optionalSection: e.target.value,
                                            };
                                          } else return ups;
                                        },
                                      );
                                      setPromptSections(updatedPromptSections);
                                    }}
                                    value={ps?.optionalSection}
                                  >
                                    <MenuItem value={true}>Optional</MenuItem>
                                    <MenuItem value={false}>Mandatory</MenuItem>
                                  </Select>
                                </FormControl>
                                {/* {promptsConfigurable ? ( */}
                                <Typography
                                  variant="body2"
                                  color={'text.secondary'}
                                >
                                  Placeholders
                                </Typography>
                                {/* ) : null} */}
                              </Box>
                              {promptsConfigurable ? (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  // justifyContent={'space-between'}
                                  gap={1}
                                >
                                  <TextField
                                    placeholder="placeholder"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    name="placeholder"
                                    value={placeholder?.[ps?.type]}
                                    onChange={(e) =>
                                      setPlaceholder((prev) => {
                                        return {
                                          ...prev,
                                          [ps?.type]: e.target.value,
                                        };
                                      })
                                    }
                                    // fullWidth
                                  />
                                  <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<Add />}
                                    onClick={() => {
                                      if (placeholder?.[ps?.type]?.trim()) {
                                        const updatedPlaceholdersArr = [
                                          ...Object.keys(
                                            ps?.placeHolders || {},
                                          ),
                                          placeholder?.[ps?.type],
                                        ];
                                        const updatedPromptSections = [
                                          ...promptSections,
                                        ];
                                        setPromptSections((prev) => {
                                          const map = {};
                                          updatedPlaceholdersArr.map(
                                            (uph) => (map[uph] = ''),
                                          );
                                          console.log(map);
                                          return updatedPromptSections?.map(
                                            (ups) => {
                                              if (ups.type === ps.type) {
                                                return {
                                                  ...ups,
                                                  placeHolders: map,
                                                };
                                              } else {
                                                return ups;
                                              }
                                            },
                                          );
                                        });
                                        setPlaceholder((prev) => {
                                          return {
                                            ...prev,
                                            [ps?.type]: '',
                                          };
                                        });
                                      }
                                    }}
                                  >
                                    Add placeholder
                                  </Button>
                                </Box>
                              ) : null}
                              {ps?.placeHolders &&
                              Object.keys(ps?.placeHolders) &&
                              Array.isArray(Object.keys(ps?.placeHolders)) &&
                              Object.keys(ps?.placeHolders)?.length > 0 ? (
                                <Box
                                  width={1}
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                  flexWrap={'wrap'}
                                >
                                  {Object.keys(ps?.placeHolders)?.map(
                                    (ph, index) => (
                                      <Box
                                        width={1}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        flexWrap={'wrap'}
                                        gap={1}
                                      >
                                        <Typography>{ph}</Typography>
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                        >
                                          {ps?.type ===
                                          'Personality_Description' ? (
                                            <Autocomplete
                                              sx={{ width: 300 }}
                                              size="small"
                                              id="personality-select"
                                              options={personalities}
                                              value={ps?.placeHolders?.[ph]}
                                              onChange={(e, newValue) => {
                                                const updatedPlaceholders = {
                                                  ...ps.placeHolders,
                                                };
                                                updatedPlaceholders[
                                                  ph
                                                ] = newValue;
                                                setPromptSections((prev) => {
                                                  return prev?.map((ups) => {
                                                    if (ups.type === ps.type) {
                                                      return {
                                                        ...ups,
                                                        placeHolders: updatedPlaceholders,
                                                      };
                                                    } else {
                                                      return ups;
                                                    }
                                                  });
                                                });
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  size="small"
                                                  {...params}
                                                  label="Placeholder Value"
                                                />
                                              )}
                                            />
                                          ) : (
                                            <TextField
                                              placeholder="placeholder value"
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              name="placeholderValue"
                                              value={ps?.placeHolders?.[ph]}
                                              onChange={(e) => {
                                                const updatedPlaceholders = {
                                                  ...ps.placeHolders,
                                                };
                                                updatedPlaceholders[ph] =
                                                  e.target.value;
                                                setPromptSections((prev) => {
                                                  return prev?.map((ups) => {
                                                    if (ups.type === ps.type) {
                                                      return {
                                                        ...ups,
                                                        placeHolders: updatedPlaceholders,
                                                      };
                                                    } else {
                                                      return ups;
                                                    }
                                                  });
                                                });
                                              }}
                                              // fullWidth
                                            />
                                          )}
                                          {/* <Tooltip
                                            arrow
                                            placement="top"
                                            title="Remove"
                                          >
                                            <IconButton
                                              size="small"
                                              disabled
                                              onClick={() => {
                                                const updatedPlaceholdersArr = [
                                                  ...Object.keys(
                                                    ps?.placeHolders,
                                                  ),
                                                ];
                                                const updatedPromptSections = [
                                                  ...promptSections,
                                                ];
                                                setPromptSections((prev) => {
                                                  const map = {};
                                                  updatedPlaceholdersArr
                                                    .filter((ups) => ups !== ph)
                                                    .map(
                                                      (uph) => (map[uph] = ''),
                                                    );
                                                  console.log(map);
                                                  return updatedPromptSections?.map(
                                                    (ups) => {
                                                      if (
                                                        ups.type === ps.type
                                                      ) {
                                                        return {
                                                          ...ups,
                                                          placeHolders: map,
                                                        };
                                                      } else {
                                                        return ups;
                                                      }
                                                    },
                                                  );
                                                });
                                              }}
                                            >
                                              <Delete sx={{ fontSize: 16 }} />
                                            </IconButton>
                                          </Tooltip> */}
                                        </Box>
                                      </Box>
                                    ),
                                  )}
                                </Box>
                              ) : null}
                              <Box>
                                <Typography
                                  variant="body2"
                                  color={'text.secondary'}
                                >
                                  Prompt
                                </Typography>
                                <TextField
                                  // disabled={!promptsConfigurable}
                                  placeholder="Prompt"
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  name="prompt"
                                  multiline
                                  rows={6}
                                  fullWidth
                                  value={ps?.text}
                                  onChange={(e) => {
                                    if (promptsConfigurable) {
                                      setPromptSections((prev) => {
                                        return prev?.map((ups) => {
                                          if (ups.type === ps.type) {
                                            return {
                                              ...ups,
                                              text: e.target.value,
                                            };
                                          } else {
                                            return ups;
                                          }
                                        });
                                      });
                                    }
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  color={'text.secondary'}
                                >
                                  Default Prompt
                                </Typography>
                                <TextField
                                  autoFocus
                                  placeholder="Section Default Prompt"
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  multiline
                                  rows={4}
                                  sx={{ mt: 1 }}
                                  name="sectionDefaultPrompt"
                                  value={ps?.defaultText || ''}
                                  onChange={(e) => {
                                    if (promptsConfigurable) {
                                      const updatedPromptSections = promptSections?.map(
                                        (ups) => {
                                          if (ups?.type === ps?.type) {
                                            return {
                                              ...ups,
                                              defaultText: e.target.value,
                                            };
                                          } else return ups;
                                        },
                                      );
                                      setPromptSections(updatedPromptSections);
                                    }
                                  }}
                                  fullWidth
                                />
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={1}
              sx={{
                borderTop: '1px solid #d3d3d3',
                padding: '8px 16px',
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  if (selectedBot === 'create') {
                    dispatch(
                      createNewBot(
                        {
                          botAudio: botAudio,
                          description: description,
                          promptSections: promptSections,
                          multilingual: multilingual,
                          // published: published,
                          botType: botType,
                          ragIndexName: ragIndexName,
                          defaultResponse: defaultResponse,
                          visibility: visibility,
                          draft: draft === 'true' ? true : false,
                          title: botTitle,
                          countries,
                          promptId: selectedPrompt,
                          vapiEnabled,
                        },
                        (data) => {
                          console.log('create bot respones', data);
                          dispatch(getAllBots(15, null, () => {}));
                          setSelectedBot('');
                          resetFields();
                        },
                      ),
                    );
                  } else {
                    dispatch(
                      updateBot(
                        selectedBot,
                        {
                          botAudio: botAudio,
                          description: description,
                          promptSections: promptSections,
                          multilingual: multilingual,
                          // published: published,
                          visibility: visibility,
                          botType: botType,
                          defaultResponse: defaultResponse,
                          ragIndexName: ragIndexName,
                          draft: draft === 'true' ? true : false,
                          title: botTitle,
                          countries,
                          promptId: selectedPrompt,
                          vapiEnabled,
                        },
                        (data) => {
                          console.log('update bot respones', data);
                          dispatch(getAllBots(15, null, () => {}));
                          setSelectedBot('');
                          resetFields();
                        },
                      ),
                    );
                  }
                }}
              >
                {selectedBot === 'create' ? 'Create Bot' : 'Update Bot'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(assignDialog)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setAssignDialog('');
                resetFields();
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Assign Bot</Typography>
          </Box>
          <Box ref={drawerContentRef} position={'relative'}>
            <Tabs
              value={orgType}
              onChange={(e, newValue) => setOrgType(newValue)}
              aria-label="Org Type Tabs"
              orientation={'horizontal'}
              // indicatorColor={theme.palette.background.default}
              variant="scrollable"
              // scrollButtons={!isMd}
              allowScrollButtonsMobile
            >
              <Tab
                value={'n_org'}
                label={
                  <Typography variant="body1">Direct Customers</Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              <Tab
                value={'w_org'}
                label={
                  <Typography variant="body1">Whitelabel Customers</Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
            </Tabs>
            <Box
              sx={{
                width: '100%',
                background: '#fff',
                p: 2,
                zIndex: 12,
              }}
            >
              <form onSubmit={formikSearch.handleSubmit}>
                <TextField
                  label="Search"
                  variant="outlined"
                  name={'searchKeyword'}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  value={formikSearch.values.searchKeyword}
                  onChange={(e) => {
                    formikSearch.setFieldValue('searchKeyword', e.target.value);
                  }}
                />
              </form>
            </Box>
            <Box
              sx={{
                minHeight: 'calc(100vh - 240px)',
                maxHeight: 'calc(100vh - 240px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                },
                paddingX: 2,
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
              }}
            >
              {allOrganizations?.fetching ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    // type="submit"
                    color="primary"
                    disabled
                  >
                    Loading ...
                    <CircularProgress size={20} />
                  </Button>
                </Box>
              ) : (
                allOrganizations?.organizations?.map((org) => (
                  <Box
                    // flex={1}
                    key={org?.id}
                    sx={{
                      padding: '8px 16px',
                      border: '1px solid #d3d3d3',
                      whiteSpace: 'nowrap',
                      position: 'relative',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={'text.primary'}
                      fontWeight={'bold'}
                      sx={{
                        color: 'text.primary',
                      }}
                    >
                      {org?.organizationName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={'text.secondary'}
                      // fontWeight={'bold'}
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      {org?.customFields?.users?.length || 0} Members
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={'text.secondary'}
                      // fontWeight={'bold'}
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      {org?.customFields?.users?.find(
                        (user) => user?.id === org?.adminId,
                      )?.email || '--'}
                    </Typography>
                    <Button
                      variant={
                        org?.assignedBots?.includes(assignDialog)
                          ? 'outlined'
                          : 'contained'
                      }
                      size="small"
                      color={
                        org?.assignedBots?.includes(assignDialog)
                          ? 'error'
                          : 'primary'
                      }
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 20,
                        padding: '8px 16px',
                      }}
                      // disabled={org?.assignedBots?.includes(assignDialog)}
                      onClick={() => {
                        dispatch(
                          assignBot(
                            assignDialog,
                            org?.id,
                            !org?.assignedBots?.includes(assignDialog),
                            () => {
                              alert('bot is assigned successfully');
                              dispatch(
                                getAllOrganizations(
                                  [],
                                  true,
                                  orgType,
                                  15,
                                  null,
                                  () => {},
                                ),
                              );
                            },
                          ),
                        );
                      }}
                    >
                      {org?.assignedBots?.includes(assignDialog)
                        ? 'Unassign'
                        : 'Assign'}
                    </Button>
                  </Box>
                ))
              )}
            </Box>
            {allOrganizations?.fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more organizations ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : allOrganizations?.emptyList === false &&
              allOrganizations?.organizations?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleOrgListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showCopyPublicPracticeLink)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowCopyPublicPracticeLink('');
                setLink('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              Copy Public Practice Link
            </Typography>
          </Box>
          <Box
            ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Box
              p={2}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              alignItems={'start'}
            >
              <Typography variant="subtitle2" color={'text.secondary'}>
                Email
              </Typography>
              <TextField
                autoFocus
                placeholder="Email"
                variant="outlined"
                color="primary"
                type="email"
                size="small"
                name="email"
                value={email}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={!email?.trim()}
                onClick={() => {
                  dispatch(
                    createPracticeCallLink(
                      email,
                      showCopyPublicPracticeLink,
                      async (data) => {
                        console.log(data);
                        setLink(
                          `${actionCodeBaseUrl}call/practice?tk=${data?.token}&pc=${data?.pass}`,
                        );
                        // try {
                        //   await navigator.clipboard.writeText(
                        //     `${actionCodeBaseUrl}practice/call?tk=${data}`,
                        //   );
                        //   alert('Link Copied to clipboard');
                        // } catch (err) {
                        //   alert('Failed to copy link');
                        //   console.error('Failed to copy: ', err);
                        // }
                      },
                    ),
                  );
                }}
              >
                Generate Link
              </Button>
              {link ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Typography color={'text.secondary'} variant="caption">
                    Link
                  </Typography>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                      background: '#f1f1f1',
                      border: '1px solid #d3d3d3',
                      borderRadius: 2,
                      padding: 1,
                    }}
                  >
                    <Typography variant="subtitle1">{link}</Typography>
                    <Tooltip title="Copy Link" arrow placement="top">
                      <ContentCopy
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(link);
                            alert('Link Copied to clipboard');
                          } catch (err) {
                            alert('Failed to copy link');
                            console.error('Failed to copy: ', err);
                          }
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(createConversationDialog)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setCreateConversationDialog('');
                // resetFields();
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              Create Conversation
            </Typography>
          </Box>
          <Box
            ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 60px)',
              maxHeight: 'calc(100vh - 60px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Conversation Title *
                  </Typography>
                  <TextField
                    autoFocus
                    placeholder="Title"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="title"
                    value={conversationTitle}
                    onChange={(e) => setConversationTitle(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      About Yourself
                    </Typography>
                    <Divider sx={{ width: 0.7 }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 2,
                      gap: 1,
                    }}
                  >
                    <Typography variant="caption" color={'text.secondary'}>
                      Your Name *
                    </Typography>
                    <TextField
                      // autoFocus
                      placeholder="Your Name *"
                      variant="outlined"
                      // label={'Your Name'}
                      color="primary"
                      size="small"
                      name="sellerName"
                      value={sellerName}
                      onChange={(e) => setSellerName(e.target.value)}
                      fullWidth
                    />
                    <Typography variant="caption" color={'text.secondary'}>
                      Your Company Name *
                    </Typography>
                    <TextField
                      // autoFocus
                      placeholder="Your Company Name *"
                      variant="outlined"
                      // label={'Your Company Name'}
                      color="primary"
                      size="small"
                      name="sellerCompanyName"
                      value={sellerCompanyName}
                      onChange={(e) => setSellerCompanyName(e.target.value)}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      About Your Target (Prospect you will be selling to)
                    </Typography>
                    <Divider sx={{ width: 0.3 }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 2,
                      gap: 1,
                    }}
                  >
                    <Box>
                      <Typography variant="caption" color={'text.secondary'}>
                        Your Target&apos;s Vertical (The vertical your prospect
                        belongs to)*
                      </Typography>
                      <br />
                      <Typography variant="caption" color={'text.secondary'}>
                        Select or type your own
                      </Typography>
                    </Box>
                    <Autocomplete
                      size="small"
                      value={vertical}
                      onChange={(event, newValue) => {
                        setVertical(newValue);
                        // console.log(newValue);
                      }}
                      id="vertical"
                      freeSolo
                      // multiple
                      options={verticalOptions}
                      getOptionLabel={(option) => option || ''}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Typography key={index}>{option}</Typography>
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setVertical(e.target.value)}
                        />
                      )}
                      sx={{
                        minWidth: { xs: 1, sm: 1, md: 400 },
                      }}
                    />
                    <Box>
                      <Typography variant="caption" color={'text.secondary'}>
                        Your Target&apos;s Designation (Designation of your
                        prospect)*
                      </Typography>
                      <br />
                      <Typography variant="caption" color={'text.secondary'}>
                        Select or type your own
                      </Typography>
                    </Box>
                    <Autocomplete
                      size="small"
                      value={designation}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          // Prevent's default 'Enter' behavior.
                          event.defaultMuiPrevented = true;
                          // your handler code
                        } else if (event.key === 'Tab') {
                          event.preventDefault();
                          if (event.target.value.trim()) {
                            setDesignation(event.target.value);
                          }
                        }
                      }}
                      onChange={(event, newValue) => {
                        setDesignation(newValue);
                        // console.log(newValue);
                      }}
                      id="designation"
                      freeSolo
                      // multiple
                      options={designationOptions}
                      getOptionLabel={(option) => option || ''}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Typography key={index}>{option}</Typography>
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      )}
                      sx={{
                        minWidth: { xs: 1, sm: 1, md: 400 },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Your Competitors
                    </Typography>
                    <Divider sx={{ width: 0.7 }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    alignItems={'start'}
                    marginLeft={2}
                  >
                    <Box>
                      <Typography variant="caption" color={'text.secondary'}>
                        Products competing with yours (comma separated)
                      </Typography>
                    </Box>
                    <TextField
                      placeholder="Name the competitors(comma seperated)"
                      variant="outlined"
                      name={'competitors'}
                      // fullWidth
                      multiline
                      rows={3}
                      size="small"
                      sx={{
                        minWidth: { xs: 1, sm: 1, md: 400 },
                      }}
                      value={competitors}
                      onChange={(e) => setCompetitors(e.target.value)}
                      type="text"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      GPT Configurations
                    </Typography>
                    <Divider sx={{ width: 0.7 }} />
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    placeholder="maxTokens"
                    variant="outlined"
                    name={'maxTokens'}
                    label={'maxTokens'}
                    // fullWidth
                    // multiline
                    // rows={3}
                    size="small"
                    // sx={{
                    //   minWidth: { xs: 1, sm: 1, md: 400 },
                    // }}
                    value={gptConfig?.maxTokens}
                    onChange={(e) => {
                      setGptConfig((prev) => {
                        return {
                          ...prev,
                          maxTokens: e.target.value,
                        };
                      });
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    placeholder="topP"
                    variant="outlined"
                    name={'topP'}
                    label={'topP'}
                    // fullWidth
                    // multiline
                    // rows={3}
                    size="small"
                    // sx={{
                    //   minWidth: { xs: 1, sm: 1, md: 400 },
                    // }}
                    value={gptConfig?.topP}
                    onChange={(e) => {
                      setGptConfig((prev) => {
                        return {
                          ...prev,
                          topP: e.target.value,
                        };
                      });
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    placeholder="frequencyPenalty"
                    variant="outlined"
                    name={'frequencyPenalty'}
                    label={'frequencyPenalty'}
                    // fullWidth
                    // multiline
                    // rows={3}
                    size="small"
                    // sx={{
                    //   minWidth: { xs: 1, sm: 1, md: 400 },
                    // }}
                    value={gptConfig?.frequencyPenalty}
                    onChange={(e) => {
                      setGptConfig((prev) => {
                        return {
                          ...prev,
                          frequencyPenalty: e.target.value,
                        };
                      });
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    placeholder="presencePenalty"
                    variant="outlined"
                    name={'presencePenalty'}
                    label={'presencePenalty'}
                    // fullWidth
                    // multiline
                    // rows={3}
                    size="small"
                    // sx={{
                    //   minWidth: { xs: 1, sm: 1, md: 400 },
                    // }}
                    value={gptConfig?.presencePenalty}
                    onChange={(e) => {
                      setGptConfig((prev) => {
                        return {
                          ...prev,
                          presencePenalty: e.target.value,
                        };
                      });
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    placeholder="temperature"
                    variant="outlined"
                    name={'temperature'}
                    label={'temperature'}
                    // fullWidth
                    // multiline
                    // rows={3}
                    size="small"
                    // sx={{
                    //   minWidth: { xs: 1, sm: 1, md: 400 },
                    // }}
                    value={gptConfig?.temperature}
                    onChange={(e) => {
                      setGptConfig((prev) => {
                        return {
                          ...prev,
                          temperature: e.target.value,
                        };
                      });
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: createError
                        ? 'space-between'
                        : 'flex-end',
                    }}
                  >
                    {createError && (
                      <Alert severity="error">{createError}</Alert>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      // sx={{ alignSelf: 'end' }}
                      disabled={
                        !conversationTitle?.trim() || creatingConversation
                      }
                      onClick={(e) => {
                        if (
                          !conversationTitle?.trim() ||
                          !vertical?.trim() ||
                          !designation?.trim() ||
                          !sellerName?.trim() ||
                          !sellerCompanyName?.trim()
                        ) {
                          setCreateError(
                            'Please fill out the required details.',
                          );
                        } else {
                          setError('');
                          setCreatingConversation(true);
                          dispatch(
                            createNewConversation(
                              {
                                description: '',
                                title: conversationTitle?.trim(),
                                botId: createConversationDialog,
                                sellerCompanyName,
                                sellerName: sellerName?.split(' ')?.[0],
                                configs: {
                                  Basic_Conversation_Instructions: {
                                    '<%>sales_person_name<%>': sellerName?.split(
                                      ' ',
                                    )?.[0],
                                    '<%>company_name<%>': sellerCompanyName,
                                  },
                                  VERTICAL: {
                                    '<%>vertical<%>': vertical,
                                  },
                                  DESIGNATION: {
                                    '<%>designation<%>': designation,
                                  },
                                  COMPETITORS: {
                                    '<%>competitors<%>': competitors,
                                  },
                                },
                                gptConfig: gptConfig,
                              },
                              (data) => {
                                setCreatingConversation(false);
                                setConversationTitle('');
                                history.push({
                                  pathname:
                                    '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls-admin',
                                  state: {
                                    conversationId: data,
                                  },
                                });
                              },
                            ),
                          );
                        }
                      }}
                    >
                      Create Conversation
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPromptPreview)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPromptPreview('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              {showPromptPreviewFor}(Prompt Preview)
            </Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {showPromptPreview}
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this bot?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteBot(showConfirmDeleteDialog, () => {
                  dispatch({
                    type: 'UPDATE_BOTS_AFTER_DELETE',
                    payload: {
                      id: showConfirmDeleteDialog,
                    },
                  });
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDuplicateDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              This will create new bot as duplicate of this bot.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDuplicateDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                createNewBot(
                  {
                    botAudio: bots?.find(
                      (bt) => bt?.id === showConfirmDuplicateDialog,
                    )?.botAudio,
                    description: bots?.find(
                      (bt) => bt?.id === showConfirmDuplicateDialog,
                    )?.description,
                    promptSections: bots?.find(
                      (bt) => bt?.id === showConfirmDuplicateDialog,
                    )?.promptSections,
                    published: false,
                    title: bots?.find(
                      (bt) => bt?.id === showConfirmDuplicateDialog,
                    )?.title,
                    countries,
                    promptId: bots?.find(
                      (bt) => bt?.id === showConfirmDuplicateDialog,
                    )?.promptId,
                    vapiEnabled,
                  },
                  (data) => {
                    console.log('create bot respones', data);
                    dispatch(getAllBots(15, null, () => {}));
                    setShowConfirmDuplicateDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default Bots;
