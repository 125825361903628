import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExpandMore,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Search,
  Send,
  Settings,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import updateOrganization from 'redux/actions/Common/updateOrganization';
import { capitalizeText, validateEmail } from 'utils';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getPlans from 'redux/actions/Common/getPlans';
import updatePlanForOrganization from 'redux/actions/Common/updatePlanForOrganization';
import refreshUsage from 'redux/actions/Common/refreshUsage';
import refreshUsageForOrg from 'redux/actions/Common/refreshUsageForOrg';
import updatePlanForOrgUser from 'redux/actions/Common/updatePlanForOrgUser';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import createNewPrompt from 'redux/actions/Common/createNewPrompt';
import updatePlanTrialEndsOn from 'redux/actions/Common/updatePlanTrialEndsOn';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import assignPlanToWlOrg from 'redux/actions/Common/assignPlanToWlOrg';
import getPlansAssignedToOrg from 'redux/actions/Common/getPlansAssignedToOrg';
import getAddOns from 'redux/actions/Common/getAddOns';
import deepEqual from 'deep-equal';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchemaPlaybook = yup.object().nullable();
const validationSchemaSearch = yup.object().nullable();

const Customers = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    organizations,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allOrganizations);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [updatingRole, setUpdatingRole] = useState(false);

  const [organizationName, setOrganizationName] = useState('');
  const [country, setCountry] = useState('united states');
  const [withPlaybook, setWithPlaybook] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [teamRole, setTeamRole] = useState('WL_ORG_ADMIN');
  const [error, setError] = useState('');

  const [sendingInvite, setSendingInvite] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedWlAddOns, setSelectedWlAddOns] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [fetchingAddons, setFetchingAddons] = useState(false);
  const [addons, setAddons] = useState([]);

  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [tags, setTags] = useState([]);

  const [showAddCustomMethodForm, setShowAddCustomMethodForm] = useState(false);

  const [placeholder, setPlaceholder] = useState('');
  const [customMethodologies, setCustomMethodologies] = useState([]);
  const [promptRequest, setPromptRequest] = useState({
    description: '',
    methodName: '',
    responseType: 'string',
    promptSections: [
      {
        defaultText: '',
        optionalSection: false,
        placeHolders: {},
        text: '',
        type: 'sales_call_analysis_method',
      },
    ],
    promptType: 'custom_method_prompt',
    title: 'Prompt for custom method',
  });

  const [finalPromptRequests, setFinalPromptRequests] = useState([]);
  const [newPromptAdded, setNewPromptAdded] = useState([]);

  const [showUpdateTrialDialog, setShowUpdateTrialDialog] = useState(false);
  const [type, setType] = useState('date');
  const [date, setDate] = useState(Date.now());
  const [days, setDays] = useState(14);

  const [withContentScoringConfig, setWithContentScoringConfig] = useState(
    false,
  );
  const [
    showEditContentScoringConfig,
    setShowEditContentScoringConfig,
  ] = useState(false);

  const [contentScoringConfig, setContentScoringConfig] = useState({
    inbound: null,
    outbound: null,
  });

  const [callType, setCallType] = useState('outbound');
  const [sameConfig, setSameConfig] = useState(true);

  const [criteriaName, setCriteriaName] = useState('');
  const [criteriaFactorName, setCriteriaFactorName] = useState('');

  const [contentScoreConfigText, setContentScoreConfigText] = useState({
    inbound: '',
    outbound: '',
  });
  const [configType, setConfigType] = useState('form');
  const [roleplayVisibilityControl, setRoleplayVisibilityControl] = useState(
    false,
  );
  const [features, setFeatures] = useState({
    'Sales Call Analysis': ['Analysis History', 'Start Analysis'],
    'Private Roleplays': ['All Roleplays', 'Start Roleplay'],
    'Clients Dashboard': ['Ask Donna'],
    'Account Settings': ['Ask Donna'],
  });

  const [featureMapping, setFeatureMapping] = useState({});
  const [showUpdateFeatureMapping, setShowUpdateFeatureMapping] = useState(
    false,
  );

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    keyFeatures: '',
    idealCustomerProfile: '',
    methodologies: [],
    customerQualificationQuestions: '',
    objectionsAndAnswers: [
      {
        question: '',
        answer: '',
      },
    ],
    challengesForCustomer: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
    awardsRatingAndReviews: '',
  };

  const initialValuesSearch = {
    searchKeyword: '',
  };

  const handleSubmit = () => {
    // dispatch(
    //   updatePlaybookForUser(
    //     selectedUser,
    //     {
    //       // playbook: playbook,
    //       organization: companyName,
    //     },
    //     () => {
    //       dispatch(getAllUsers(designation, 15, null, () => {}));
    //       alert('Information Updated!');
    //     },
    //   ),
    // );
  };

  const onSubmitPlaybook = (values) => {
    console.log(values);
    if (withPlaybook) {
      if (customMethodologies && customMethodologies.length > 0) {
        dispatch(
          updateOrganization(
            selectedOrganization,
            {
              organizationName,
              methodologies: formikPlaybook.values.methodologies,
              contentScoringConfig: withContentScoringConfig
                ? {
                    inbound: sameConfig
                      ? contentScoringConfig.outbound
                      : contentScoringConfig.inbound,
                    outbound: contentScoringConfig.outbound,
                  }
                : null,
              customMethodologies,
              rolePlayVisibilityControl: roleplayVisibilityControl,
              navBarTitles: featureMapping,
              playbookTags: tags,
              playbookStructure: {
                bant: formikPlaybook.values.methodologies.includes('bant'),
                companyName: formikPlaybook?.values?.companyName,
                competingProducts: formikPlaybook.values.competingProducts,
                customerQualificationQuestions:
                  formikPlaybook.values.customerQualificationQuestions,
                customerQuestions: formikPlaybook.values.objectionsAndAnswers,
                challengesForCustomer:
                  formikPlaybook.values.challengesForCustomer,
                expectedCallToAction:
                  formikPlaybook.values.expectedCallToAction,
                idealCustomerProfile:
                  formikPlaybook.values.idealCustomerProfile,
                meddpic: formikPlaybook.values.methodologies.includes(
                  'meddpic',
                ),
                playbook: formikPlaybook.values.methodologies.includes(
                  'playbook',
                ),
                productDescription: formikPlaybook.values.productDescription,
                productKeyFeatures: formikPlaybook.values.keyFeatures,
                spin: formikPlaybook.values.methodologies.includes('spin'),
                star: formikPlaybook.values.methodologies.includes('star'),
              },
            },
            () => {
              // setFinalPromptRequests([]);
              // dispatch(getAllOrganizations(true, 'w_org', 15, null, () => {}));
              if (finalPromptRequests.length === 0) {
                dispatch(
                  getAllOrganizations([], true, 'w_org', 15, null, () => {
                    setSelectedOrganization(null);
                  }),
                );
              }
              alert('Information Updated!');
            },
          ),
        );
        finalPromptRequests?.forEach((pr) => {
          if (
            !organizations
              ?.find((org) => org?.id === selectedOrganization)
              ?.customMethodologies?.includes(pr?.methodName) &&
            customMethodologies?.includes(pr?.methodName)
          ) {
            dispatch(
              createNewPrompt(pr, (data) => {
                setNewPromptAdded((prev) => [...prev, pr?.methodName]);
              }),
            );
          } else {
            return;
          }
        });
      } else {
        dispatch(
          updateOrganization(
            selectedOrganization,
            {
              organizationName,
              methodologies: formikPlaybook.values.methodologies,
              contentScoringConfig: withContentScoringConfig
                ? {
                    inbound: sameConfig
                      ? contentScoringConfig.outbound
                      : contentScoringConfig.inbound,
                    outbound: contentScoringConfig.outbound,
                  }
                : null,
              customMethodologies: [],
              navBarTitles: featureMapping,
              rolePlayVisibilityControl: roleplayVisibilityControl,
              playbookTags: tags,
              playbookStructure: {
                bant: formikPlaybook.values.methodologies.includes('bant'),
                companyName: formikPlaybook?.values?.companyName,
                competingProducts: formikPlaybook.values.competingProducts,
                customerQualificationQuestions:
                  formikPlaybook.values.customerQualificationQuestions,
                customerQuestions: formikPlaybook.values.objectionsAndAnswers,
                challengesForCustomer:
                  formikPlaybook.values.challengesForCustomer,
                expectedCallToAction:
                  formikPlaybook.values.expectedCallToAction,
                idealCustomerProfile:
                  formikPlaybook.values.idealCustomerProfile,
                meddpic: formikPlaybook.values.methodologies.includes(
                  'meddpic',
                ),
                playbook: formikPlaybook.values.methodologies.includes(
                  'playbook',
                ),
                productDescription: formikPlaybook.values.productDescription,
                productKeyFeatures: formikPlaybook.values.keyFeatures,
                spin: formikPlaybook.values.methodologies.includes('spin'),
                star: formikPlaybook.values.methodologies.includes('star'),
              },
            },
            () => {
              dispatch(
                getAllOrganizations([], true, 'w_org', 15, null, () => {
                  setSelectedOrganization(null);
                }),
              );
              alert('Information Updated!');
            },
          ),
        );
      }
    }
  };

  const onSearch = (values) => {
    console.log(values);
    if (values?.searchKeyword?.trim()) {
      dispatch(
        getAllOrganizations(
          [values?.searchKeyword],
          true,
          'w_org',
          15,
          null,
          () => {},
        ),
      );
    } else {
      dispatch(getAllOrganizations([], true, 'w_org', 15, null, () => {}));
    }
  };

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const formikSearch = useFormik({
    initialValues: initialValuesSearch,
    validationSchema: validationSchemaSearch,
    onSubmit: onSearch,
  });

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        organizations &&
        organizations.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllOrganizations(
          formikSearch?.values?.searchKeyword
            ? [formikSearch?.values?.searchKeyword]
            : [],
          true,
          'w_org',
          15,
          lastTime,
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    if (finalPromptRequests && finalPromptRequests.length === 0) {
      dispatch(getAllOrganizations([], true, 'w_org', 15, null, () => {}));
    }
  }, [finalPromptRequests]);

  useEffect(() => {
    if (newPromptAdded && newPromptAdded.length > 0) {
      setFinalPromptRequests((prev) => {
        return prev.filter((pr) => !newPromptAdded.includes(pr?.methodName));
      });
    }
  }, [newPromptAdded]);

  useEffect(() => {
    if (selectedOrganization) {
      setCustomMethodologies(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.customMethodologies || [],
      );
      setSelectedPlan(
        organizations?.find((org) => org?.id === selectedOrganization)?.plan
          ?.id,
      );
      setCountry(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.countries?.[0] || 'united states',
      );
      setOrganizationName(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.organizationName || '',
      );
      formikPlaybook.setValues({
        companyName:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.companyName || '',
        productDescription:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.productDescription || '',
        keyFeatures:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.productKeyFeatures || '',
        idealCustomerProfile:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.idealCustomerProfile || '',
        methodologies:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.methodologies || [],
        competingProducts:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.competingProducts || '',
        expectedCallToAction:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.expectedCallToAction || '',
        customerQualificationQuestions:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.customerQualificationQuestions || '',
        objectionsAndAnswers:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.customerQuestions || [],
        challengesForCustomer:
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.playbookStructure?.challengesForCustomer || [],
      });
      setDate(
        organizations?.find((org) => org?.id === selectedOrganization)?.plan
          ?.trailEndsOn || Date.now(),
      );
      setWithContentScoringConfig(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig || false,
      );
      setRoleplayVisibilityControl(
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.rolePlayVisibilityControl || false,
      );
      setFeatureMapping(
        Object.keys(features || {})?.reduce((acc, curr1) => {
          if (acc[curr1] === undefined) {
            acc[curr1] = {
              emptyText: '',
              loadingText: '',
              subTitles: features?.[curr1]?.reduce((acc, curr2) => {
                if (acc[curr2] === undefined) {
                  acc[curr2] = {
                    title:
                      organizations?.find(
                        (org) => org?.id === selectedOrganization,
                      )?.navBarTitles?.[curr1]?.subTitles?.[curr2]?.title || '',
                  };
                }
                return acc;
              }, {}),
              title:
                organizations?.find((org) => org?.id === selectedOrganization)
                  ?.navBarTitles?.[curr1]?.title || '',
            };
          }
          return acc;
        }, {}),
      );
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && plans && plans?.length > 0) {
      console.log('plans', plans);
      dispatch(
        getPlansAssignedToOrg(selectedOrganization, (assignedPlans) => {
          console.log(assignedPlans);
          setSelectedPlans(
            plans
              ?.filter((plan) =>
                assignedPlans?.map((p) => p?.parentPlanId)?.includes(plan?.id),
              )
              ?.map((p) => p?.id),
          );
        }),
      );
    }
  }, [selectedOrganization, plans]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedPlans &&
      selectedPlans.length > 0 &&
      plans &&
      plans.length > 0 &&
      addons &&
      addons.length > 0
    ) {
      setSelectedWlAddOns(
        addons
          .filter((addon) =>
            organizations
              ?.find((org) => org?.id === selectedOrganization)
              ?.planAddOns?.map((planAddon) => planAddon?.feature?.feature)
              ?.includes(addon?.feature?.feature),
          )
          ?.map((addon) => addon?.id),
      );
    }
  }, [selectedOrganization, selectedPlans, plans, addons]);

  useEffect(() => {
    if (
      selectedPlans &&
      selectedPlans.length > 0 &&
      plans &&
      plans.length > 0
    ) {
      setFetchingAddons(true);
      dispatch(
        getAddOns(true, selectedPlans, (addons) => {
          setAddons(addons);
          setFetchingAddons(false);
        }),
      );
    }
  }, [selectedPlans]);

  useEffect(() => {
    if (showEditContentScoringConfig) {
      setSameConfig(
        deepEqual(
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.contentScoringConfig?.inbound,
          organizations?.find((org) => org?.id === selectedOrganization)
            ?.contentScoringConfig?.outbound,
        ),
      );
      if (
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig?.inbound
      ) {
        setContentScoringConfig((prev) => {
          return {
            ...prev,
            inbound: organizations?.find(
              (org) => org?.id === selectedOrganization,
            )?.contentScoringConfig?.inbound,
          };
        });
        setContentScoreConfigText((prev) => {
          return {
            ...prev,
            inbound: JSON.stringify(
              Object.keys(
                organizations?.find((org) => org?.id === selectedOrganization)
                  ?.contentScoringConfig?.inbound,
              )?.reduce((acc, curr) => {
                acc[curr] = organizations?.find(
                  (org) => org?.id === selectedOrganization,
                )?.contentScoringConfig?.inbound?.[curr];
                return acc;
              }, {}),
              null,
              2,
            ),
          };
        });
      } else {
        dispatch(
          getContentScoringConfigTemplate((data) => {
            setContentScoringConfig((prev) => {
              return {
                ...prev,
                inbound: data?.callType?.inbound,
              };
            });
            setContentScoreConfigText((prev) => {
              return {
                ...prev,
                inbound: JSON.stringify(data?.callType?.inbound, null, 2),
              };
            });
          }),
        );
      }
      if (
        organizations?.find((org) => org?.id === selectedOrganization)
          ?.contentScoringConfig?.outbound
      ) {
        setContentScoringConfig((prev) => {
          return {
            ...prev,
            outbound: organizations?.find(
              (org) => org?.id === selectedOrganization,
            )?.contentScoringConfig?.outbound,
          };
        });
        setContentScoreConfigText((prev) => {
          return {
            ...prev,
            outbound: JSON.stringify(
              Object.keys(
                organizations?.find((org) => org?.id === selectedOrganization)
                  ?.contentScoringConfig?.outbound,
              )?.reduce((acc, curr) => {
                acc[curr] = organizations?.find(
                  (org) => org?.id === selectedOrganization,
                )?.contentScoringConfig?.outbound?.[curr];
                return acc;
              }, {}),
              null,
              2,
            ),
          };
        });
      } else {
        dispatch(
          getContentScoringConfigTemplate((data) => {
            setContentScoringConfig((prev) => {
              return {
                ...prev,
                outbound: data?.callType?.outbound,
              };
            });
            setContentScoreConfigText((prev) => {
              return {
                ...prev,
                outbound: JSON.stringify(data?.callType?.outbound, null, 2),
              };
            });
          }),
        );
      }
    }
  }, [showEditContentScoringConfig, selectedOrganization]);

  useEffect(() => {
    dispatch(getAllOrganizations([], true, 'w_org', 15, null, () => {}));
    dispatch(
      getPlans((plans) => {
        setPlans(plans);
        // setFetchingPlans(false);
      }),
    );
    setFeatureMapping(
      Object.keys(features || {})?.reduce((acc, curr) => {
        if (acc[curr] === undefined) {
          acc[curr] = {
            emptyText: '',
            loadingText: '',
            subTitles: features?.[curr]?.reduce((acc, curr) => {
              if (acc[curr] === undefined) {
                acc[curr] = {
                  title: '',
                };
              }
              return acc;
            }, {}),
            title: '',
          };
        }
        return acc;
      }, {}),
    );
  }, []);

  // console.log(newPromptAdded);
  // console.log(finalPromptRequests);
  // console.log(selectedPlans);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          sx={{ position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 2,
              zIndex: 12,
            }}
          >
            <form onSubmit={formikSearch.handleSubmit}>
              <TextField
                label="Search"
                variant="outlined"
                name={'searchKeyword'}
                size="small"
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={formikSearch.values.searchKeyword}
                onChange={(e) => {
                  formikSearch.setFieldValue('searchKeyword', e.target.value);
                }}
              />
            </form>
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            // display={selectedOrganization ? 'none' : 'flex'}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                    paddingTop: 10,
                  }
            }
          >
            {fetching ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <CircularProgress size={16} />
                <Typography>Loading organizations ...</Typography>
              </Box>
            ) : (
              organizations?.map((org) => (
                <Box
                  // flex={1}
                  key={org?.id}
                  sx={{
                    // flex: 1,
                    width: 1,
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    cursor: 'pointer',
                    background:
                      selectedOrganization === org?.id
                        ? theme.palette.primary.main
                        : 'default',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setSelectedOrganization(org?.id)}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.primary',
                    }}
                  >
                    {org?.organizationName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.secondary',
                    }}
                  >
                    {org?.customFields?.users?.length || 0} Members
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrganization === org?.id
                          ? '#fff'
                          : 'text.secondary',
                    }}
                  >
                    {org?.customFields?.users?.find(
                      (user) => user?.id === org?.adminId,
                    )?.email || '--'}
                  </Typography>
                </Box>
              ))
            )}
            {fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more organizations ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && organizations?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? 0 : 0,
              }}
            >
              {selectedOrganization ? (
                <Box>
                  <Accordion
                    expanded={settingsExpanded}
                    onChange={() => {
                      setSettingsExpanded((prev) => !prev);
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="submission-content"
                      id="submission-header"
                      sx={{
                        height: '30px',
                        background: '#d3d3d3',
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      Organization Settings
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display={'flex'} gap={2}>
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            alignItems={'start'}
                            sx={{
                              flex: 1,
                              padding: 2,
                              border: '1px solid #d3d3d3',
                              borderRadius: 2,
                              position: 'relative',
                              paddingTop: 4,
                            }}
                          >
                            <Typography
                              variant={'body1'}
                              sx={{
                                position: 'absolute',
                                top: -20,
                                left: 10,
                                background: '#fff',
                                padding: 1,
                              }}
                              color={'text.secondary'}
                            >
                              Update Plan
                            </Typography>
                            <Box display={'flex'} gap={1}>
                              <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  dispatch(
                                    refreshUsageForOrg(
                                      selectedOrganization,
                                      () => {
                                        alert('Information Updated');
                                      },
                                    ),
                                  );
                                }}
                              >
                                Refresh Usage
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  setShowUpdateTrialDialog(true);
                                }}
                              >
                                Update Validity
                              </Button>
                            </Box>
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                              // sx={{ marginBottom: 1 }}
                            >
                              Select Available Plans (WL Cusomers will be using
                              these to invite their customer)
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                                size="small"
                                labelId="plan-select-label"
                                id="plan-select"
                                name="selectedPlan"
                                multiple
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  console.log(e.target);
                                  dispatch(
                                    assignPlanToWlOrg(
                                      selectedOrganization,
                                      plans
                                        ?.filter?.(
                                          (plan) =>
                                            plan?.forWlOrg &&
                                            e.target.value.includes(plan?.id),
                                        )
                                        ?.map((p) => p?.id),
                                      true,
                                      () => {
                                        dispatch(
                                          assignPlanToWlOrg(
                                            selectedOrganization,
                                            plans
                                              ?.filter?.(
                                                (plan) =>
                                                  plan?.forWlOrg &&
                                                  selectedPlans?.includes?.(
                                                    plan?.id,
                                                  ) &&
                                                  !e.target.value.includes(
                                                    plan?.id,
                                                  ),
                                              )
                                              ?.map((p) => p?.id),
                                            false,
                                            () => {
                                              //       alert('Information updated');
                                              setSelectedPlans(e.target.value);
                                            },
                                          ),
                                        );
                                      },
                                    ),
                                  );
                                  // console.log(
                                  //   e.target.value.map((planId) => {
                                  //     return {
                                  //       [planId]: selectedPlans?.includes(
                                  //         planId,
                                  //       ),
                                  //     };
                                  //   }),
                                  // );
                                  // setSelectedPlans(e.target.value);
                                  // dispatch(
                                  //   assignPlanToWlOrg(
                                  //     selectedOrganization,
                                  //     e.target.value,
                                  //     () => {
                                  //       setSelectedPlans(e.target.value);
                                  //       alert('Information updated');
                                  //     },
                                  //   ),
                                  // );
                                }}
                                value={selectedPlans}
                              >
                                {plans
                                  ?.filter((plan) => plan?.forWlOrg)
                                  ?.map((plan) => (
                                    <MenuItem key={plan?.id} value={plan?.id}>
                                      {capitalizeText(plan?.title)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {addons && addons?.length > 0 ? (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  // sx={{ marginBottom: 1 }}
                                >
                                  Select Available Addons (WL Customers will be
                                  using these to invite their customer)
                                </Typography>
                                <FormControl
                                  sx={{
                                    width: '60%',
                                  }}
                                >
                                  <Select
                                    size="small"
                                    labelId="wl-addons-select-label"
                                    id="wl-addons-select"
                                    name="selectedWlAddOns"
                                    multiple
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateOrganization(
                                          selectedOrganization,
                                          {
                                            planAddOns: addons?.filter(
                                              (addon) =>
                                                e.target.value.includes(
                                                  addon?.id,
                                                ),
                                            ),
                                          },
                                          () => {
                                            setSelectedWlAddOns(e.target.value);
                                            dispatch(
                                              getAllOrganizations(
                                                [],
                                                true,
                                                'w_org',
                                                15,
                                                null,
                                                () => {},
                                              ),
                                            );
                                            alert('Information Updated!');
                                          },
                                        ),
                                      );
                                    }}
                                    value={selectedWlAddOns}
                                  >
                                    {addons?.map((addon) => (
                                      <MenuItem
                                        key={addon?.id}
                                        value={addon?.id}
                                      >
                                        {capitalizeText(
                                          addon?.feature?.feature
                                            ?.split('_')
                                            ?.join(' '),
                                        )}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </>
                            ) : null}
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                              // sx={{ marginBottom: 1 }}
                            >
                              Select Plan(This plan will be WL customer default
                              plan)
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                                size="small"
                                labelId="plan-select-label"
                                id="plan-select"
                                name="selectedPlan"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  dispatch(
                                    updatePlanForOrganization(
                                      selectedOrganization,
                                      e.target.value,
                                      () => {
                                        setSelectedPlan(e.target.value);
                                        alert('Information updated');
                                      },
                                    ),
                                  );
                                }}
                                value={selectedPlan}
                              >
                                {plans
                                  ?.filter((plan) => !plan?.forWlOrg)
                                  ?.map((plan) => (
                                    <MenuItem key={plan?.id} value={plan?.id}>
                                      {capitalizeText(plan?.title)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {/* <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                              // sx={{ marginBottom: 1 }}
                            >
                              Select Available Addons (This plan will be WL
                              customer default addons)
                            </Typography>
                            <FormControl
                              sx={{
                                width: '60%',
                              }}
                            >
                              <Select
                                size="small"
                                labelId="addon-select-label"
                                id="addon-select"
                                name="selectedAddOns"
                                multiple
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  setSelectedAddOns(e.target.value);
                                }}
                                value={selectedAddOns}
                              >
                                {addons?.map((addon) => (
                                  <MenuItem key={addon?.id} value={addon?.id}>
                                    {capitalizeText(
                                      addon?.feature?.feature
                                        ?.split('_')
                                        ?.join(' '),
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            alignItems={'start'}
                            sx={{
                              flex: 1,
                              padding: 2,
                              border: '1px solid #d3d3d3',
                              borderRadius: 2,
                              position: 'relative',
                              paddingTop: 4,
                            }}
                          >
                            <Typography
                              variant={'body1'}
                              sx={{
                                position: 'absolute',
                                top: -20,
                                left: 10,
                                background: '#fff',
                                padding: 1,
                              }}
                              color={'text.secondary'}
                            >
                              Update Details
                            </Typography>
                            <FormControl sx={{ minWidth: 300 }}>
                              <InputLabel id="country-select-label">
                                Select Country
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="country-select-label"
                                id="country-select"
                                name="country"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => setCountry(e.target.value)}
                                value={country}
                                input={<OutlinedInput label="Select Country" />}
                              >
                                <MenuItem key={0} value="india">
                                  India
                                </MenuItem>
                                <MenuItem key={1} value="united states">
                                  United States
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              sx={{ minWidth: 300 }}
                              label="Organization Name"
                              variant="outlined"
                              name={'organizationName'}
                              size="small"
                              // fullWidth
                              value={organizationName}
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                            />
                            <Grid item xs={12}>
                              <Typography
                                variant={'subtitle2'}
                                // sx={{  }}
                                color={'text.secondary'}
                                fontWeight={'bold'}
                              >
                                <span>
                                  Pick all the sales methodologies that the
                                  sales team is expected to follow.
                                </span>
                              </Typography>
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup row>
                                  {formikPlaybook.values.methodologies?.map(
                                    (mthd) => (
                                      <FormControlLabel
                                        checked={formikPlaybook.values.methodologies?.includes(
                                          mthd,
                                        )}
                                        control={
                                          <Checkbox
                                            size="small"
                                            value={mthd}
                                            name="methods"
                                          />
                                        }
                                        label={
                                          mthd === 'meddpic'
                                            ? 'MEDDICC'
                                            : mthd.toUpperCase()
                                        }
                                        onChange={(e) => {
                                          if (
                                            formikPlaybook.values.methodologies?.includes(
                                              mthd,
                                            )
                                          ) {
                                            formikPlaybook.setFieldValue(
                                              'methodologies',
                                              formikPlaybook.values.methodologies.filter(
                                                (mthd) =>
                                                  mthd !== e.target.value,
                                              ),
                                            );
                                          } else {
                                            formikPlaybook.setFieldValue(
                                              'methodologies',
                                              [
                                                ...formikPlaybook.values
                                                  .methodologies,
                                                e.target.value,
                                              ],
                                            );
                                          }
                                        }}
                                      />
                                    ),
                                  )}
                                  {customMethodologies?.map((mthd) => (
                                    <FormControlLabel
                                      checked={customMethodologies?.includes(
                                        mthd,
                                      )}
                                      control={
                                        <Checkbox
                                          size="small"
                                          value={mthd}
                                          name="customMethod"
                                        />
                                      }
                                      label={mthd?.toUpperCase()}
                                      onChange={(e) => {
                                        if (
                                          customMethodologies?.includes(mthd)
                                        ) {
                                          setCustomMethodologies(
                                            customMethodologies.filter(
                                              (mthd) => mthd !== e.target.value,
                                            ),
                                          );
                                        } else {
                                          setCustomMethodologies([
                                            ...customMethodologies,
                                            e.target.value,
                                          ]);
                                        }
                                      }}
                                    />
                                  ))}
                                </FormGroup>
                              </FormControl>
                              <Typography
                                variant={'subtitle2'}
                                color={'text.secondary'}
                                fontWeight={'bold'}
                              >
                                Custom Methodology
                              </Typography>
                              <Button
                                variant="outlined"
                                startIcon={<Add />}
                                size="small"
                                sx={{
                                  padding: '4px 8px',
                                }}
                                onClick={() => setShowAddCustomMethodForm(true)}
                              >
                                Add custom methodologies
                              </Button>
                              {/* </>
                              )} */}
                            </Grid>
                            <Button
                              variant="outlined"
                              startIcon={<Settings />}
                              size="small"
                              sx={{
                                padding: '4px 8px',
                              }}
                              onClick={() => setShowUpdateFeatureMapping(true)}
                            >
                              Configure Feature Mapping
                            </Button>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={withContentScoringConfig}
                                    name="withContentScoringConfig"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Call Scoring Configurations
                                  </Typography>
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setShowEditContentScoringConfig(true);
                                  } else {
                                    setShowEditContentScoringConfig(false);
                                  }
                                  setWithContentScoringConfig(e.target.checked);
                                }}
                              />
                              {withContentScoringConfig ? (
                                <Button
                                  startIcon={<Settings />}
                                  onClick={() =>
                                    setShowEditContentScoringConfig(true)
                                  }
                                >
                                  Edit Configurations
                                </Button>
                              ) : null}
                            </Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={roleplayVisibilityControl}
                                  name="roleplayVisibilityControl"
                                />
                              }
                              label={
                                <Typography variant="subtitle2">
                                  Roleplay visibility control
                                </Typography>
                              }
                              onChange={(e) => {
                                setRoleplayVisibilityControl(e.target.checked);
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={withPlaybook}
                                  name="withPlaybook"
                                />
                              }
                              label={
                                <Typography variant="subtitle2">
                                  Playbook
                                </Typography>
                              }
                              onChange={(e) => {
                                setWithPlaybook(e.target.checked);
                              }}
                            />
                            {withPlaybook ? (
                              <Box>
                                <form onSubmit={formikPlaybook.handleSubmit}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      <Box>
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          fontWeight={'bold'}
                                        >
                                          Playbook Title
                                        </Typography>
                                      </Box>
                                      <AutoCompleteMUI
                                        id="tags"
                                        value={tags}
                                        onChange={(event, newValue, reason) => {
                                          setTags([...newValue]);
                                          // console.log(reason);
                                        }}
                                        options={[]}
                                        onTextChange={(e) =>
                                          setTags([e.target.value])
                                        }
                                        onKeyDown={() => {}}
                                      />
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          // marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        About Your Company
                                      </Typography>
                                      <TextField
                                        placeholder="Enter about your company"
                                        variant="outlined"
                                        name={'companyName'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values.companyName
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched.companyName &&
                                          Boolean(
                                            formikPlaybook.errors.companyName,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched.companyName &&
                                          formikPlaybook.errors.companyName
                                        }
                                        type={'text'}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        color={'text.secondary'}
                                        variant={'subtitle2'}
                                        sx={{ marginBottom: 1 }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Product Description
                                        </span>
                                        <br />
                                        (Please describe your product in detail.
                                        Please also add URLs to specific
                                        webpages and shared documents if any.)
                                      </Typography>
                                      <TextField
                                        placeholder="Enter product description"
                                        variant="outlined"
                                        name={'productDescription'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .productDescription
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .productDescription &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .productDescription,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .productDescription &&
                                          formikPlaybook.errors
                                            .productDescription
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        color={'text.secondary'}
                                        variant={'subtitle2'}
                                        sx={{ marginBottom: 1 }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Product Key Features
                                        </span>
                                        <br />
                                        (Highlight USPs)
                                      </Typography>
                                      <TextField
                                        placeholder="Enter product key features"
                                        variant="outlined"
                                        name={'keyFeatures'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values.keyFeatures
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched.keyFeatures &&
                                          Boolean(
                                            formikPlaybook.errors.keyFeatures,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched.keyFeatures &&
                                          formikPlaybook.errors.keyFeatures
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Describe your Ideal Customer Profile
                                      </Typography>
                                      <TextField
                                        placeholder="Describe your ideal customer profile"
                                        variant="outlined"
                                        name={'idealCustomerProfile'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .idealCustomerProfile
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .idealCustomerProfile &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .idealCustomerProfile,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .idealCustomerProfile &&
                                          formikPlaybook.errors
                                            .idealCustomerProfile
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Customer Qualification Questions:{' '}
                                        </span>
                                        (1 or more questions that you use to
                                        qualify your customers)
                                      </Typography>
                                      <TextField
                                        placeholder="Customer Qualification Questions:"
                                        variant="outlined"
                                        name={'customerQualificationQuestions'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .customerQualificationQuestions
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .customerQualificationQuestions &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .customerQualificationQuestions,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .customerQualificationQuestions &&
                                          formikPlaybook.errors
                                            .customerQualificationQuestions
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Top customer challenges and
                                          recommended responses
                                        </span>
                                      </Typography>
                                      {formikPlaybook.values
                                        .challengesForCustomer &&
                                        Array.isArray(
                                          formikPlaybook.values
                                            .challengesForCustomer,
                                        ) &&
                                        formikPlaybook.values
                                          .challengesForCustomer.length > 0 &&
                                        formikPlaybook.values.challengesForCustomer?.map(
                                          (objAndAns, faqIndex) => {
                                            return (
                                              <Box
                                                key={faqIndex}
                                                sx={{
                                                  border: '1px solid #d3d3d3',
                                                  borderRadius: 2,
                                                  padding: 2,
                                                  marginTop: 1,
                                                  position: 'relative',
                                                }}
                                              >
                                                <Button
                                                  variant="text"
                                                  sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 10,
                                                    zIndex: 2,
                                                    background: '#fff',
                                                    '&:hover': {
                                                      background: '#fff',
                                                    },
                                                  }}
                                                  onClick={() => {
                                                    const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.filter(
                                                      (objAndAns, i) =>
                                                        i !== faqIndex,
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'challengesForCustomer',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                >
                                                  Remove
                                                </Button>
                                                <TextField
                                                  label={`Challenge ${faqIndex +
                                                    1}`}
                                                  size="small"
                                                  variant="outlined"
                                                  fullWidth
                                                  value={objAndAns?.question}
                                                  sx={{ marginTop: 1 }}
                                                  onChange={(e) => {
                                                    const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.map(
                                                      (objAndAns, i) => {
                                                        if (i === faqIndex) {
                                                          return {
                                                            ...objAndAns,
                                                            question:
                                                              e.target.value,
                                                          };
                                                        } else {
                                                          return objAndAns;
                                                        }
                                                      },
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'challengesForCustomer',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  size="small"
                                                  label={`Recommended Response`}
                                                  variant="outlined"
                                                  fullWidth
                                                  value={objAndAns?.answer}
                                                  onChange={(e) => {
                                                    const updatedObjAndAns = formikPlaybook.values.challengesForCustomer.map(
                                                      (objAndAns, i) => {
                                                        if (i === faqIndex) {
                                                          return {
                                                            ...objAndAns,
                                                            answer:
                                                              e.target.value,
                                                          };
                                                        } else {
                                                          return objAndAns;
                                                        }
                                                      },
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'challengesForCustomer',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                  sx={{ marginTop: 1 }}
                                                />
                                              </Box>
                                            );
                                          },
                                        )}
                                      <Button
                                        onClick={() => {
                                          formikPlaybook.setFieldValue(
                                            'challengesForCustomer',
                                            [
                                              ...formikPlaybook.values
                                                .challengesForCustomer,
                                              {
                                                question: '',
                                                answer: '',
                                              },
                                            ],
                                          );
                                        }}
                                        sx={{ marginTop: 1 }}
                                        variant="contained"
                                      >
                                        Add Challenge and Recommended Response
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Typical customer objections and their
                                          answers
                                        </span>
                                      </Typography>
                                      {formikPlaybook.values
                                        .objectionsAndAnswers &&
                                        Array.isArray(
                                          formikPlaybook.values
                                            .objectionsAndAnswers,
                                        ) &&
                                        formikPlaybook.values
                                          .objectionsAndAnswers.length > 0 &&
                                        formikPlaybook.values.objectionsAndAnswers?.map(
                                          (objAndAns, faqIndex) => {
                                            return (
                                              <Box
                                                key={faqIndex}
                                                sx={{
                                                  border: '1px solid #d3d3d3',
                                                  borderRadius: 2,
                                                  padding: 2,
                                                  marginTop: 1,
                                                  position: 'relative',
                                                }}
                                              >
                                                <Button
                                                  variant="text"
                                                  sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 10,
                                                    zIndex: 2,
                                                    background: '#fff',
                                                    '&:hover': {
                                                      background: '#fff',
                                                    },
                                                  }}
                                                  onClick={() => {
                                                    const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.filter(
                                                      (objAndAns, i) =>
                                                        i !== faqIndex,
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'objectionsAndAnswers',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                >
                                                  Remove
                                                </Button>
                                                <TextField
                                                  label={`Objection ${faqIndex +
                                                    1}`}
                                                  size="small"
                                                  variant="outlined"
                                                  fullWidth
                                                  value={objAndAns?.question}
                                                  sx={{ marginTop: 1 }}
                                                  onChange={(e) => {
                                                    const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.map(
                                                      (objAndAns, i) => {
                                                        if (i === faqIndex) {
                                                          return {
                                                            ...objAndAns,
                                                            question:
                                                              e.target.value,
                                                          };
                                                        } else {
                                                          return objAndAns;
                                                        }
                                                      },
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'objectionsAndAnswers',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  size="small"
                                                  label={`Answer`}
                                                  variant="outlined"
                                                  fullWidth
                                                  value={objAndAns?.answer}
                                                  onChange={(e) => {
                                                    const updatedObjAndAns = formikPlaybook.values.objectionsAndAnswers.map(
                                                      (objAndAns, i) => {
                                                        if (i === faqIndex) {
                                                          return {
                                                            ...objAndAns,
                                                            answer:
                                                              e.target.value,
                                                          };
                                                        } else {
                                                          return objAndAns;
                                                        }
                                                      },
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'objectionsAndAnswers',
                                                      updatedObjAndAns,
                                                    );
                                                  }}
                                                  sx={{ marginTop: 1 }}
                                                  // error={formik.touched.email && Boolean(formik.errors.email)}
                                                  // helperText={formik.touched.email && formik.errors.email}
                                                />
                                              </Box>
                                            );
                                          },
                                        )}
                                      <Button
                                        onClick={() => {
                                          formikPlaybook.setFieldValue(
                                            'objectionsAndAnswers',
                                            [
                                              ...formikPlaybook.values
                                                .objectionsAndAnswers,
                                              {
                                                question: '',
                                                answer: '',
                                              },
                                            ],
                                          );
                                        }}
                                        sx={{ marginTop: 1 }}
                                        variant="contained"
                                      >
                                        Add Objection and Answer
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Expected Call to Action
                                        </span>
                                        [e.g. Close the deal, Book a demo,
                                        schedule next meeting, etc]
                                      </Typography>
                                      <TextField
                                        placeholder="Enter expected call to action"
                                        variant="outlined"
                                        name={'expectedCallToAction'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .expectedCallToAction
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .expectedCallToAction &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .expectedCallToAction,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .expectedCallToAction &&
                                          formikPlaybook.errors
                                            .expectedCallToAction
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Competing Products and Companies
                                        </span>
                                        [comma separated product or company
                                        names]
                                      </Typography>
                                      <TextField
                                        placeholder="Competing Products and Companies"
                                        variant="outlined"
                                        name={'competingProducts'}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .competingProducts
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .competingProducts &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .competingProducts,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .competingProducts &&
                                          formikPlaybook.errors
                                            .competingProducts
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Highlights of Awards, Ratings, and
                                          Customer Reviews
                                        </span>
                                      </Typography>
                                      <TextField
                                        placeholder="Highlights of Awards, Ratings, and Customer Reviews"
                                        variant="outlined"
                                        name={
                                          'playbookStructure.awardsRatingAndReviews'
                                        }
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={
                                          formikPlaybook.values
                                            .playbookStructure
                                            ?.awardsRatingAndReviews
                                        }
                                        onChange={formikPlaybook.handleChange}
                                        error={
                                          formikPlaybook.touched
                                            .playbookStructure
                                            ?.awardsRatingAndReviews &&
                                          Boolean(
                                            formikPlaybook.errors
                                              .playbookStructure
                                              ?.awardsRatingAndReviews,
                                          )
                                        }
                                        helperText={
                                          formikPlaybook.touched
                                            .playbookStructure
                                            ?.awardsRatingAndReviews &&
                                          formikPlaybook.errors
                                            .playbookStructure
                                            ?.awardsRatingAndReviews
                                        }
                                        type="text"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box display="flex"></Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Button
                                        variant="contained"
                                        disabled={false}
                                        type="submit"
                                      >
                                        {'Update'}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </form>
                              </Box>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  if (
                                    customMethodologies &&
                                    customMethodologies.length > 0
                                  ) {
                                    dispatch(
                                      updateOrganization(
                                        selectedOrganization,
                                        {
                                          countries: [country],
                                          methodologies:
                                            formikPlaybook.values.methodologies,
                                          contentScoringConfig: withContentScoringConfig
                                            ? {
                                                inbound: sameConfig
                                                  ? contentScoringConfig.outbound
                                                  : contentScoringConfig.inbound,
                                                outbound:
                                                  contentScoringConfig.outbound,
                                              }
                                            : null,
                                          navBarTitles: featureMapping,
                                          rolePlayVisibilityControl: roleplayVisibilityControl,
                                          customMethodologies: customMethodologies,
                                          organizationName,
                                        },
                                        () => {
                                          // setFinalPromptRequests([]);
                                          if (
                                            finalPromptRequests.length === 0
                                          ) {
                                            dispatch(
                                              getAllOrganizations(
                                                [],
                                                true,
                                                'w_org',
                                                15,
                                                null,
                                                () => {
                                                  setSelectedOrganization(null);
                                                },
                                              ),
                                            );
                                          }
                                          alert('Information Updated!');
                                        },
                                      ),
                                    );
                                    finalPromptRequests?.forEach((pr) => {
                                      if (
                                        !organizations
                                          ?.find(
                                            (org) =>
                                              org?.id === selectedOrganization,
                                          )
                                          ?.customMethodologies?.includes(
                                            pr?.methodName,
                                          ) &&
                                        customMethodologies?.includes(
                                          pr?.methodName,
                                        )
                                      ) {
                                        dispatch(
                                          createNewPrompt(pr, (data) => {
                                            setNewPromptAdded((prev) => [
                                              ...prev,
                                              pr?.methodName,
                                            ]);
                                          }),
                                        );
                                      }
                                    });
                                  } else {
                                    dispatch(
                                      updateOrganization(
                                        selectedOrganization,
                                        {
                                          countries: [country],
                                          methodologies:
                                            formikPlaybook.values.methodologies,
                                          contentScoringConfig: withContentScoringConfig
                                            ? {
                                                inbound: sameConfig
                                                  ? contentScoringConfig.outbound
                                                  : contentScoringConfig.inbound,
                                                outbound:
                                                  contentScoringConfig.outbound,
                                              }
                                            : null,
                                          navBarTitles: featureMapping,
                                          organizationName,
                                          rolePlayVisibilityControl: roleplayVisibilityControl,
                                          customMethodologies: [],
                                        },
                                        () => {
                                          dispatch(
                                            getAllOrganizations(
                                              [],
                                              true,
                                              'w_org',
                                              15,
                                              null,
                                              () => {
                                                setSelectedOrganization(null);
                                              },
                                            ),
                                          );
                                          alert('Information Updated!');
                                        },
                                      ),
                                    );
                                  }
                                }}
                              >
                                Update
                              </Button>
                            )}
                          </Box>
                        </Box>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                          alignItems={'start'}
                          sx={{
                            flex: 1,
                            padding: 2,
                            border: '1px solid #d3d3d3',
                            borderRadius: 2,
                            position: 'relative',
                            paddingTop: 4,
                          }}
                        >
                          <Typography
                            variant={'body1'}
                            sx={{
                              position: 'absolute',
                              top: -20,
                              left: 10,
                              background: '#fff',
                              padding: 1,
                            }}
                            color={'text.secondary'}
                          >
                            Invite Team Member
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                              >
                                First Name
                              </Typography>
                              <TextField
                                placeholder="First Name"
                                variant="outlined"
                                name={'firstName'}
                                size="small"
                                sx={{
                                  width: '100%',
                                }}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                              >
                                Last Name
                              </Typography>
                              <TextField
                                placeholder="Last Name"
                                variant="outlined"
                                name={'lastName'}
                                // fullWidth
                                // multiline
                                // rows={3}
                                size="small"
                                sx={{
                                  width: '100%',
                                }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                              >
                                Email
                              </Typography>
                              <TextField
                                placeholder="Email"
                                variant="outlined"
                                name={'email'}
                                // fullWidth
                                // multiline
                                // rows={3}
                                size="small"
                                sx={{
                                  width: '100%',
                                }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                // sx={{ marginBottom: 1 }}
                              >
                                Select Role
                              </Typography>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  labelId="role-select-label"
                                  id="role-select"
                                  name="teamRole"
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) => setTeamRole(e.target.value)}
                                  value={teamRole}
                                >
                                  {/* <MenuItem key={0} value="WL_ORG_STANDARD">
                                    STANDARD
                                  </MenuItem> */}
                                  <MenuItem key={1} value="WL_ORG_ADMIN">
                                    Whitelabel ADMIN
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            {error && (
                              <Grid item xs={12}>
                                <Alert severity="error">{error}</Alert>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                // sx={{ marginLeft: 'auto' }}
                                onClick={() => {
                                  if (
                                    email &&
                                    email?.trim() &&
                                    validateEmail(email?.trim())
                                  ) {
                                    setError('');
                                    setSendingInvite(true);
                                    dispatch(
                                      sendTeamInvite(
                                        selectedOrganization,
                                        {
                                          designations: ['account_admin'],
                                          email,
                                          emailVerified: true,
                                          firstName,
                                          lastName,
                                          teamRole: [teamRole],
                                        },
                                        (error) => {
                                          if (error) {
                                            setSendingInvite(false);
                                            alert('Failed to send invite');
                                          } else {
                                            dispatch(
                                              getAllOrganizations(
                                                [],
                                                true,
                                                'w_org',
                                                15,
                                                null,
                                                () => {},
                                              ),
                                            );
                                            alert('Invite Sent Successfully');
                                            setSendingInvite(false);
                                          }
                                        },
                                      ),
                                    );
                                  } else {
                                    setError('Please enter a valid email.');
                                  }
                                }}
                              >
                                Send Invite
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <TableContainer
                    component={Paper}
                    sx={{
                      marginTop: 1,
                      minHeight: isXs
                        ? 'calc(100vh - 140px)'
                        : settingsExpanded
                        ? 'calc(100vh - 400px)'
                        : 'calc(100vh - 160px)',
                      maxHeight: isXs
                        ? 'calc(100vh - 140px)'
                        : settingsExpanded
                        ? 'calc(100vh - 400px)'
                        : 'calc(100vh - 160px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                    }}
                    // onScroll={handleListScroll}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow
                          sx={{
                            '& .MuiTableCell-root': {
                              border: '1px solid #e1e1e1',
                            },
                          }}
                        >
                          <TableCell rowSpan={2} align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            >
                              Member
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Roleplays
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Sales Calls
                            </Typography>
                          </TableCell>
                          <TableCell align="center" rowSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Joined On
                            </Typography>
                          </TableCell>
                          <TableCell align="center" rowSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Actions
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center" rowSpan={2}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                              align="center"
                            >
                              Actions
                            </Typography>
                          </TableCell> */}
                        </TableRow>
                        <TableRow
                          sx={{
                            '& .MuiTableCell-root': {
                              border: '1px solid #e1e1e1',
                            },
                          }}
                        >
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Calls Analyzed
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Speaking Time(Minutes)
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Calls Analyzed
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Speaking Time(Minutes)
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {organizations
                          ?.find((org) => org?.id === selectedOrganization)
                          ?.customFields?.users.map((member, i) => (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  // gap={1}
                                >
                                  <Typography
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    {member?.email}
                                  </Typography>
                                  <Typography variant="caption">
                                    {member?.fullName || ''}
                                  </Typography>
                                  <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      dispatch(
                                        refreshUsage(member?.id, () => {
                                          alert('Information Updated');
                                        }),
                                      );
                                    }}
                                  >
                                    Refresh Usage
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                {member?.custom?.counts?.practiceCallAnalysed >
                                0 ? (
                                  // <Button
                                  //   variant="text"
                                  //   color="primary"
                                  //   onClick={() => {
                                  //     setViewType('practiceCalls');
                                  //     setCurrentMemberId(member?.id);
                                  //   }}
                                  // >
                                  <Typography
                                    variant="subtitle2"
                                    // fontWeight={'bold'}
                                    sx={
                                      {
                                        // textDecoration: 'underline',
                                      }
                                    }
                                  >
                                    {member?.custom?.counts
                                      ?.practiceCallAnalysed || 0}
                                  </Typography>
                                ) : (
                                  // </Button>
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {member?.custom?.counts
                                      ?.practiceCallAnalysed || 0}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  // color={'text.secondary'}
                                  variant={'subtitle2'}
                                >
                                  {Math.floor(
                                    (member?.custom?.counts
                                      ?.practiceCallSpeakerTimeSumInSec || 0) /
                                      60,
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                {member?.custom?.counts?.salesCallAnalysed >
                                0 ? (
                                  // <Button
                                  //   variant="text"
                                  //   color="primary"
                                  //   onClick={() => {
                                  //     setViewType('salesCalls');
                                  //     setCurrentMemberId(member?.id);
                                  //   }}
                                  // >
                                  <Typography
                                    variant="subtitle2"
                                    // fontWeight={'bold'}
                                    sx={
                                      {
                                        // textDecoration: 'underline',
                                      }
                                    }
                                  >
                                    {member?.custom?.counts
                                      ?.salesCallAnalysed || 0}
                                  </Typography>
                                ) : (
                                  // </Button>
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {member?.custom?.counts
                                      ?.salesCallAnalysed || 0}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  // color={'text.secondary'}
                                  variant={'subtitle2'}
                                >
                                  {Math.floor(
                                    (member?.custom?.counts
                                      ?.salesCallSpeakerTimeSumInSec || 0) / 60,
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  // color={'text.secondary'}
                                  variant={'subtitle2'}
                                >
                                  {moment(member?.createdOn).format(
                                    'DD/MM/YYYY',
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Box>
                                  <FormControl fullWidth size="small">
                                    <Select
                                      disabled={
                                        // member?.email === userInfo?.email ||
                                        updatingRole
                                      }
                                      size="small"
                                      labelId="role-select-label"
                                      id="role-select"
                                      name="teamRole"
                                      MenuProps={{ disableScrollLock: true }}
                                      onChange={(e) => {
                                        setUpdatingRole(true);
                                        dispatch(
                                          updateTeamMemberRole(
                                            selectedOrganization,
                                            [
                                              {
                                                roles: [e.target.value],
                                                userId: member?.id,
                                              },
                                            ],
                                            () => {
                                              setUpdatingRole(false);
                                              dispatch(
                                                getAllOrganizations(
                                                  [],
                                                  true,
                                                  'w_org',
                                                  15,
                                                  null,
                                                  () => {},
                                                ),
                                              );
                                              alert('Information Updated!');
                                            },
                                          ),
                                        );
                                      }}
                                      value={
                                        member?.orgRoles?.includes(
                                          'WL_ORG_ADMIN',
                                        )
                                          ? 'WL_ORG_ADMIN'
                                          : 'WL_ORG_STANDARD'
                                      }
                                    >
                                      {/* <MenuItem key={0} value="WL_ORG_STANDARD">
                                        STANDARD
                                      </MenuItem> */}
                                      <MenuItem key={1} value="WL_ORG_ADMIN">
                                        Whitelabel ADMIN
                                      </MenuItem>
                                      <MenuItem key={1} value="SS_ADMIN">
                                        SS ADMIN
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {/* <FormControl fullWidth size="small">
                                    <Select
                                      size="small"
                                      labelId="plan-select-label"
                                      id="plan-select"
                                      name="selectedPlan"
                                      MenuProps={{ disableScrollLock: true }}
                                      onChange={(e) => {
                                        dispatch(
                                          updatePlanForOrgUser(
                                            selectedOrganization,
                                            e.target.value,
                                            member?.id,
                                            () => {
                                              dispatch(
                                                getAllOrganizations(
                                                  15,
                                                  null,
                                                  () => {},
                                                ),
                                              );
                                              // setSelectedPlan(e.target.value);
                                              alert('Information updated');
                                            },
                                          ),
                                        );
                                      }}
                                      value={member?.plan?.id}
                                    >
                                      {plans?.map((plan) => (
                                        <MenuItem key={0} value={plan?.id}>
                                          {capitalizeText(plan?.title)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl> */}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddCustomMethodForm)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '70vw',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            p={2}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowAddCustomMethodForm(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Methodology Name
                </Typography>
                <TextField
                  autoFocus
                  placeholder="Title"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="title"
                  value={promptRequest?.methodName}
                  onChange={(e) =>
                    setPromptRequest((prev) => {
                      return {
                        ...prev,
                        methodName: e.target.value,
                      };
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  // sx={{ marginBottom: 2 }}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Response Type
                  </FormLabel>
                  <Select
                    // ref={promptSelectRef}
                    labelId="prompt-select-label"
                    // label="Select Prompt"
                    value={promptRequest?.responseType || ''}
                    onChange={(e) => {
                      setPromptRequest((prev) => {
                        return {
                          ...prev,
                          responseType: e.target.value,
                        };
                      });
                    }}
                  >
                    <MenuItem value={'string'}>String</MenuItem>
                    <MenuItem value={'json'}>JSON</MenuItem>
                    <MenuItem value={'html'}>HTML</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    // justifyContent={'space-between'}
                    gap={1}
                  >
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Placeholders
                    </Typography>
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    // justifyContent={'space-between'}
                    gap={1}
                  >
                    <TextField
                      autoFocus
                      placeholder="placeholder"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="placeholder"
                      value={placeholder}
                      onChange={(e) => setPlaceholder(e.target.value)}
                      // fullWidth
                    />
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<Add />}
                      onClick={() => {
                        if (placeholder?.trim()) {
                          setPromptRequest((prev) => {
                            return {
                              ...prev,
                              promptSections: [
                                {
                                  ...prev?.promptSections?.[0],
                                  placeHolders: {
                                    ...prev?.promptSections?.[0]?.placeHolders,
                                    [placeholder]: '',
                                  },
                                },
                              ],
                            };
                          });
                          setPlaceholder('');
                        }
                      }}
                    >
                      Add placeholder
                    </Button>
                  </Box>
                  {promptRequest?.promptSections?.[0]?.placeHolders &&
                  Object.keys(
                    promptRequest?.promptSections?.[0]?.placeHolders,
                  ) &&
                  Array.isArray(
                    Object.keys(
                      promptRequest?.promptSections?.[0]?.placeHolders,
                    ),
                  ) &&
                  Object.keys(promptRequest?.promptSections?.[0]?.placeHolders)
                    ?.length > 0 ? (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      flexWrap={'wrap'}
                    >
                      {Object.keys(
                        promptRequest?.promptSections?.[0]?.placeHolders,
                      )?.map((ph, index) => (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                        >
                          <Chip
                            key={ph}
                            label={ph}
                            size="medium"
                            color="primary"
                          />
                          <Tooltip arrow placement="top" title="Remove">
                            <IconButton
                              size="small"
                              onClick={() => {
                                const placeholdersArr = [
                                  ...Object.keys(promptRequest?.placeHolders),
                                ];
                                const updatedPlaceholdersArr = placeholdersArr.filter(
                                  (placeholder) => placeholder !== ph,
                                );
                                setPromptRequest((prev) => {
                                  return {
                                    ...prev,
                                    promptSections: [
                                      {
                                        ...prev?.promptSections?.[0],
                                        placeHolders: updatedPlaceholdersArr?.reduce(
                                          (acc, curr) => ({
                                            ...acc,
                                            [curr]: '',
                                          }),
                                          {},
                                        ),
                                      },
                                    ],
                                  };
                                });
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                      background: '#f1f1f1',
                      padding: '8px 16px',
                    }}
                  >
                    <Typography color={'text.secondary'}>
                      {promptRequest?.promptSections?.[0]?.type}
                    </Typography>
                    <FormControl>
                      <Select
                        size="small"
                        labelId="country-select-label"
                        id="country-select"
                        name="country"
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => {
                          setPromptRequest((prev) => {
                            return {
                              ...prev,
                              promptSections: [
                                {
                                  ...promptRequest?.promptSections?.[0],
                                  optionalSection: e.target.value,
                                },
                              ],
                            };
                          });
                        }}
                        value={
                          promptRequest?.promptSections?.[0]?.optionalSection
                        }
                      >
                        <MenuItem value={true}>Optional</MenuItem>
                        <MenuItem value={false}>Mandatory</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant="body2" color={'text.secondary'}>
                      Section Prompt
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Section Prompt"
                      variant="outlined"
                      color="primary"
                      size="small"
                      multiline
                      rows={7}
                      name="sectionPrompt"
                      value={promptRequest?.promptSections?.[0]?.text}
                      onChange={(e) => {
                        setPromptRequest((prev) => {
                          return {
                            ...prev,
                            promptSections: [
                              {
                                ...promptRequest?.promptSections?.[0],
                                text: e.target.value,
                              },
                            ],
                          };
                        });
                      }}
                      fullWidth
                    />
                    <Typography variant="body2" color={'text.secondary'}>
                      Section Default Prompt (will be used in case any of the
                      placeholder value is not coming in request)
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Section Default Prompt"
                      variant="outlined"
                      color="primary"
                      size="small"
                      multiline
                      rows={4}
                      sx={{ mt: 1 }}
                      name="sectionDefaultPrompt"
                      value={
                        promptRequest?.promptSections?.[0]?.defaultText || ''
                      }
                      onChange={(e) => {
                        setPromptRequest((prev) => {
                          return {
                            ...prev,
                            promptSections: [
                              {
                                ...promptRequest?.promptSections?.[0],
                                defaultText: e.target.value,
                              },
                            ],
                          };
                        });
                      }}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Grid>
              {/* ))} */}
              <Grid item xs={12}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  gap={1}
                >
                  <Button
                    variant="contained"
                    size="small"
                    disabled={
                      promptRequest?.methodName?.trim() === '' ||
                      promptRequest?.promptSections?.[0]?.text === ''
                    }
                    onClick={() => {
                      setFinalPromptRequests((prev) => [
                        ...prev,
                        {
                          createForOrg: selectedOrganization,
                          ...promptRequest,
                          methodName: promptRequest?.methodName?.toLowerCase(),
                        },
                      ]);
                      setCustomMethodologies((prev) => [
                        ...prev,
                        promptRequest?.methodName?.toLowerCase(),
                      ]);
                      setShowAddCustomMethodForm(false);
                    }}
                  >
                    Add new methodology
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showEditContentScoringConfig}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '95vh',
            maxWidth: '70vw',
            minWidth: '70vw',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
            // paddingTop: 3,
          }}
        >
          <Typography
            variant="body1"
            padding={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            Call Scoring Configurations
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: '16px',
              minHeight: 'calc(95vh - 140px)',
              maxHeight: 'calc(95vh - 140px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
            }}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Configure with
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={configType}
                onChange={(e) => {
                  if (e.target.value === 'form') {
                    try {
                      const outboundObj = JSON.parse(
                        contentScoreConfigText?.outbound || '{}',
                      );
                      const inboundObj = JSON.parse(
                        contentScoreConfigText?.inbound || '{}',
                      );
                      setContentScoringConfig((prev) => {
                        return {
                          ...prev,
                          outbound: outboundObj,
                          inbound: inboundObj,
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to parse json text. please validate the json.',
                      );
                    }
                  } else {
                    try {
                      setContentScoreConfigText((prev) => {
                        return {
                          ...prev,
                          outbound: JSON.stringify(
                            contentScoringConfig?.outbound || '{}',
                            null,
                            2,
                          ),
                          inbound: JSON.stringify(
                            contentScoringConfig?.inbound || '{}',
                            null,
                            2,
                          ),
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to convert json to text. please try again.',
                      );
                    }
                  }
                }}
              >
                <FormControlLabel
                  value={'form'}
                  control={<Radio size="small" />}
                  label={'Form'}
                />
                <FormControlLabel
                  value={'json'}
                  control={<Radio size="small" />}
                  label={'json'}
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Call Type
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
              >
                <FormControlLabel
                  value={'outbound'}
                  control={<Radio size="small" />}
                  label={'Outbound'}
                />
                <FormControlLabel
                  value={'inbound'}
                  control={<Radio size="small" />}
                  label={'Inbound'}
                />
              </RadioGroup>
            </FormControl>
            {callType === 'inbound' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={sameConfig}
                    name="sameConfig"
                  />
                }
                label={
                  <Typography variant="subtitle2">Same as outbound</Typography>
                }
                onChange={(e) => {
                  setSameConfig(e.target.checked);
                }}
              />
            ) : null}
            {callType === 'outbound' || (callType === 'inbound' && !sameConfig)
              ? Object.keys(contentScoringConfig?.[callType] || {})?.map(
                  (configKey) => {
                    return configKey === 'criteriaList' ? (
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          position: 'relative',
                          // padding: 2,
                        }}
                        key={configKey}
                      >
                        {configType === 'form' ? (
                          <>
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              sx={{
                                position: 'absolute',
                                top: -12,
                                left: 5,
                                background: '#fff',
                                padding: '0 5px',
                                zIndex: 9999,
                              }}
                            >
                              Categories
                            </Typography>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                              // sx={{ background: '#f1f1f1' }}
                              // padding={2}
                            >
                              {contentScoringConfig[callType][configKey] ? (
                                contentScoringConfig[callType][configKey]
                                  ?.length > 0 ? (
                                  <Box sx={{}}>
                                    <Box sx={{ background: '#d3d3d3', p: 2 }}>
                                      {contentScoringConfig[callType][
                                        configKey
                                      ]?.map((criteriaKey, index) => (
                                        <Box
                                          key={
                                            Object.keys(criteriaKey || {})?.[0]
                                          }
                                          display={'flex'}
                                          flexDirection={'column'}
                                          // gap={2}
                                        >
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx={
                                              {
                                                // background: '#f1f1f1',
                                                // padding: 1,
                                              }
                                            }
                                          >
                                            <Typography variant="body1">
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {index + 1}.
                                              </span>{' '}
                                              {
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              }
                                            </Typography>
                                            <IconButton
                                              onClick={() => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]: prev[
                                                          callType
                                                        ][configKey]?.filter(
                                                          (criteriaMap) =>
                                                            Object.keys(
                                                              criteriaMap || {},
                                                            )?.[0] !==
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0],
                                                        ),
                                                        ['categoryWeight']: Object.keys(
                                                          prev[callType][
                                                            'categoryWeight'
                                                          ] || {},
                                                        )
                                                          ?.filter(
                                                            (key) =>
                                                              key !==
                                                              Object.keys(
                                                                criteriaKey ||
                                                                  {},
                                                              )?.[0],
                                                          )
                                                          ?.reduce(
                                                            (acc, curr) => {
                                                              acc[curr] =
                                                                prev[callType][
                                                                  'categoryWeight'
                                                                ][curr];
                                                              return acc;
                                                            },
                                                            {},
                                                          ),
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                            >
                                              <Delete sx={{ fontSize: 18 }} />
                                            </IconButton>
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                            sx={{
                                              padding: 1,
                                            }}
                                          >
                                            <Typography flex={1}>
                                              Category Weight
                                            </Typography>
                                            <TextField
                                              placeholder={'Category Weight'}
                                              variant="outlined"
                                              name={'factorKey'}
                                              size="small"
                                              sx={{
                                                flex: 2,
                                              }}
                                              value={
                                                contentScoringConfig[callType][
                                                  'categoryWeight'
                                                ][
                                                  Object.keys(
                                                    criteriaKey || {},
                                                  )?.[0]
                                                ]
                                              }
                                              onChange={(e) => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]:
                                                          prev[callType][
                                                            configKey
                                                          ],
                                                        categoryWeight: {
                                                          ...prev[callType][
                                                            'categoryWeight'
                                                          ],
                                                          [Object.keys(
                                                            criteriaKey || {},
                                                          )?.[0]]: e.target
                                                            .value,
                                                        },
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                              type={'number'}
                                            />
                                          </Box>
                                          <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            gap={2}
                                          >
                                            {contentScoringConfig[callType][
                                              configKey
                                            ][index][
                                              Object.keys(
                                                criteriaKey || {},
                                              )?.[0]
                                            ] ? (
                                              contentScoringConfig[callType][
                                                configKey
                                              ][index][
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              ]?.length > 0 ? (
                                                <>
                                                  {contentScoringConfig[
                                                    callType
                                                  ][configKey][index][
                                                    Object.keys(
                                                      criteriaKey || {},
                                                    )?.[0]
                                                  ]?.map(
                                                    (
                                                      criteriaKeyFactor,
                                                      factorIndex,
                                                    ) => {
                                                      return (
                                                        <Box
                                                          key={`${
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          }${factorIndex}`}
                                                          display={'flex'}
                                                          flexDirection={
                                                            'column'
                                                          }
                                                          backgroundColor={
                                                            '#fff'
                                                          }
                                                        >
                                                          <Box
                                                            display={'flex'}
                                                            gap={1}
                                                            alignItems={
                                                              'center'
                                                            }
                                                            justifyContent={
                                                              'space-between'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                              border:
                                                                '1px solid #d3d3d3',
                                                            }}
                                                          >
                                                            <Typography variant="body1">
                                                              {
                                                                Object.keys(
                                                                  criteriaKeyFactor ||
                                                                    {},
                                                                )?.[0]
                                                              }
                                                            </Typography>
                                                            <IconButton
                                                              onClick={() => {
                                                                setContentScoringConfig(
                                                                  (prev) => {
                                                                    return {
                                                                      ...prev,
                                                                      [callType]: {
                                                                        ...prev[
                                                                          callType
                                                                        ],
                                                                        [configKey]: prev[
                                                                          callType
                                                                        ][
                                                                          configKey
                                                                        ]?.map(
                                                                          (
                                                                            ck,
                                                                            cki,
                                                                          ) =>
                                                                            cki ===
                                                                            index
                                                                              ? {
                                                                                  [Object.keys(
                                                                                    ck ||
                                                                                      {},
                                                                                  )?.[0]]: prev[
                                                                                    callType
                                                                                  ][
                                                                                    configKey
                                                                                  ][
                                                                                    index
                                                                                  ][
                                                                                    Object.keys(
                                                                                      criteriaKey ||
                                                                                        {},
                                                                                    )?.[0]
                                                                                  ]?.filter(
                                                                                    (
                                                                                      key,
                                                                                    ) =>
                                                                                      Object.keys(
                                                                                        key ||
                                                                                          {},
                                                                                      )?.[0] !==
                                                                                      Object.keys(
                                                                                        criteriaKeyFactor ||
                                                                                          {},
                                                                                      )?.[0],
                                                                                  ),
                                                                                }
                                                                              : ck,
                                                                        ),
                                                                      },
                                                                    };
                                                                  },
                                                                );
                                                              }}
                                                            >
                                                              <Delete
                                                                sx={{
                                                                  fontSize: 18,
                                                                }}
                                                              />
                                                            </IconButton>
                                                          </Box>
                                                          {contentScoringConfig[
                                                            callType
                                                          ][configKey][index][
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0]
                                                          ][factorIndex][
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          ]
                                                            ? Object.keys(
                                                                contentScoringConfig[
                                                                  callType
                                                                ][configKey][
                                                                  index
                                                                ][
                                                                  Object.keys(
                                                                    criteriaKey ||
                                                                      {},
                                                                  )?.[0]
                                                                ][factorIndex][
                                                                  Object.keys(
                                                                    criteriaKeyFactor ||
                                                                      {},
                                                                  )?.[0]
                                                                ] || {},
                                                              )?.map(
                                                                (factorKey) => {
                                                                  return factorKey ===
                                                                    'description' ||
                                                                    factorKey ===
                                                                      'weight' ||
                                                                    factorKey ===
                                                                      'question' ? (
                                                                    <Box
                                                                      display={
                                                                        'flex'
                                                                      }
                                                                      flexDirection={
                                                                        'column'
                                                                      }
                                                                    >
                                                                      <Box
                                                                        display={
                                                                          'flex'
                                                                        }
                                                                        gap={1}
                                                                        // alignItems={'center'}
                                                                        sx={{
                                                                          padding: 1,
                                                                        }}
                                                                      >
                                                                        <Typography
                                                                          flex={
                                                                            1
                                                                          }
                                                                        >
                                                                          {
                                                                            factorKey
                                                                          }
                                                                        </Typography>
                                                                        <TextField
                                                                          placeholder={
                                                                            factorKey
                                                                          }
                                                                          variant="outlined"
                                                                          name={
                                                                            'factorKey'
                                                                          }
                                                                          size="small"
                                                                          multiline={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          rows={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 3
                                                                              : 1
                                                                          }
                                                                          sx={{
                                                                            flex: 2,
                                                                          }}
                                                                          value={
                                                                            contentScoringConfig[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorIndex
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKeyFactor ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorKey
                                                                            ]
                                                                          }
                                                                          onChange={(
                                                                            e,
                                                                          ) => {
                                                                            setContentScoringConfig(
                                                                              (
                                                                                prev,
                                                                              ) => {
                                                                                return {
                                                                                  ...prev,
                                                                                  [callType]: {
                                                                                    ...prev[
                                                                                      callType
                                                                                    ],
                                                                                    [configKey]: prev[
                                                                                      callType
                                                                                    ][
                                                                                      configKey
                                                                                    ]?.map(
                                                                                      (
                                                                                        ck,
                                                                                        cki,
                                                                                      ) =>
                                                                                        cki ===
                                                                                        index
                                                                                          ? {
                                                                                              [Object.keys(
                                                                                                ck ||
                                                                                                  {},
                                                                                              )?.[0]]: prev[
                                                                                                callType
                                                                                              ][
                                                                                                configKey
                                                                                              ][
                                                                                                index
                                                                                              ][
                                                                                                Object.keys(
                                                                                                  criteriaKey ||
                                                                                                    {},
                                                                                                )?.[0]
                                                                                              ]?.map(
                                                                                                (
                                                                                                  key,
                                                                                                  keyIndex,
                                                                                                ) =>
                                                                                                  keyIndex ===
                                                                                                  factorIndex
                                                                                                    ? {
                                                                                                        ...prev[
                                                                                                          callType
                                                                                                        ][
                                                                                                          configKey
                                                                                                        ][
                                                                                                          index
                                                                                                        ][
                                                                                                          Object.keys(
                                                                                                            criteriaKey ||
                                                                                                              {},
                                                                                                          )?.[0]
                                                                                                        ][
                                                                                                          factorIndex
                                                                                                        ],
                                                                                                        [Object.keys(
                                                                                                          criteriaKeyFactor ||
                                                                                                            {},
                                                                                                        )?.[0]]: {
                                                                                                          ...prev[
                                                                                                            callType
                                                                                                          ][
                                                                                                            configKey
                                                                                                          ][
                                                                                                            index
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKey ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ][
                                                                                                            factorIndex
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKeyFactor ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ],
                                                                                                          [factorKey]:
                                                                                                            e
                                                                                                              .target
                                                                                                              .value,
                                                                                                        },
                                                                                                      }
                                                                                                    : key,
                                                                                              ),
                                                                                            }
                                                                                          : ck,
                                                                                    ),
                                                                                  },
                                                                                };
                                                                              },
                                                                            );
                                                                          }}
                                                                          type={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 'text'
                                                                              : 'number'
                                                                          }
                                                                        />
                                                                      </Box>
                                                                    </Box>
                                                                  ) : null;
                                                                },
                                                              )
                                                            : null}
                                                        </Box>
                                                      );
                                                    },
                                                  )}
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                  >
                                                    <TextField
                                                      placeholder="Metric Name"
                                                      variant="outlined"
                                                      name={
                                                        'criteriaFactorName'
                                                      }
                                                      size="small"
                                                      sx={{
                                                        width: '100%',
                                                      }}
                                                      value={criteriaFactorName}
                                                      onChange={(e) =>
                                                        setCriteriaFactorName(
                                                          e.target.value,
                                                        )
                                                      }
                                                      type="text"
                                                    />
                                                    <Button
                                                      variant="contained"
                                                      size="small"
                                                      startIcon={<Add />}
                                                      disabled={
                                                        !criteriaFactorName?.trim()
                                                      }
                                                      onClick={() => {
                                                        setContentScoringConfig(
                                                          (prev) => {
                                                            return {
                                                              ...prev,
                                                              [callType]: {
                                                                ...prev[
                                                                  callType
                                                                ],
                                                                [configKey]: prev[
                                                                  callType
                                                                ][
                                                                  configKey
                                                                ]?.map(
                                                                  (ck, cki) =>
                                                                    cki ===
                                                                    index
                                                                      ? {
                                                                          [Object.keys(
                                                                            criteriaKey,
                                                                          )[0]]: [
                                                                            ...prev[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey,
                                                                              )[0]
                                                                            ],
                                                                            {
                                                                              [criteriaFactorName]: {
                                                                                description:
                                                                                  '',
                                                                                weight: 1,
                                                                              },
                                                                            },
                                                                          ],
                                                                        }
                                                                      : ck,
                                                                ),
                                                              },
                                                            };
                                                          },
                                                        );
                                                        setCriteriaFactorName(
                                                          '',
                                                        );
                                                      }}
                                                      fullWidth
                                                    >
                                                      Add new metric
                                                    </Button>
                                                  </Box>
                                                </>
                                              ) : (
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  gap={1}
                                                >
                                                  <TextField
                                                    placeholder="Metric Name"
                                                    variant="outlined"
                                                    name={'criteriaFactorName'}
                                                    size="small"
                                                    sx={{
                                                      width: '100%',
                                                    }}
                                                    value={criteriaFactorName}
                                                    onChange={(e) =>
                                                      setCriteriaFactorName(
                                                        e.target.value,
                                                      )
                                                    }
                                                    type="text"
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<Add />}
                                                    disabled={
                                                      !criteriaFactorName?.trim()
                                                    }
                                                    onClick={() => {
                                                      setContentScoringConfig(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [callType]: {
                                                              ...prev[callType],
                                                              [configKey]: prev[
                                                                callType
                                                              ][configKey]?.map(
                                                                (ck, cki) =>
                                                                  cki === index
                                                                    ? {
                                                                        [Object.keys(
                                                                          criteriaKey,
                                                                        )[0]]: [
                                                                          {
                                                                            [criteriaFactorName]: {
                                                                              description:
                                                                                '',
                                                                              weight: 1,
                                                                            },
                                                                          },
                                                                        ],
                                                                      }
                                                                    : ck,
                                                              ),
                                                            },
                                                          };
                                                        },
                                                      );
                                                      setCriteriaFactorName('');
                                                    }}
                                                    fullWidth
                                                  >
                                                    Add new metric
                                                  </Button>
                                                </Box>
                                              )
                                            ) : null}
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      p={2}
                                    >
                                      <TextField
                                        placeholder="Category Name"
                                        variant="outlined"
                                        name={'criteriaName'}
                                        size="small"
                                        sx={{
                                          width: '100%',
                                        }}
                                        value={criteriaName}
                                        onChange={(e) =>
                                          setCriteriaName(e.target.value)
                                        }
                                        type="text"
                                      />
                                      <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Add />}
                                        disabled={!criteriaName?.trim()}
                                        onClick={() => {
                                          setContentScoringConfig((prev) => {
                                            return {
                                              ...prev,
                                              [callType]: {
                                                ...prev[callType],
                                                [configKey]: [
                                                  ...prev[callType][configKey],
                                                  {
                                                    [criteriaName]: [],
                                                  },
                                                ],
                                                categoryWeight: {
                                                  ...prev[callType][
                                                    'categoryWeight'
                                                  ],
                                                  [criteriaName]: 1,
                                                },
                                              },
                                            };
                                          });
                                          setCriteriaName('');
                                        }}
                                        fullWidth
                                      >
                                        Add new category
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    p={2}
                                  >
                                    <TextField
                                      placeholder="Category Name"
                                      variant="outlined"
                                      name={'criteriaName'}
                                      size="small"
                                      sx={{
                                        width: '100%',
                                      }}
                                      value={criteriaName}
                                      onChange={(e) =>
                                        setCriteriaName(e.target.value)
                                      }
                                      type="text"
                                    />
                                    <Button
                                      variant="contained"
                                      size="small"
                                      startIcon={<Add />}
                                      disabled={!criteriaName?.trim()}
                                      onClick={() => {
                                        setContentScoringConfig((prev) => {
                                          return {
                                            ...prev,
                                            [callType]: {
                                              ...prev[callType],
                                              [configKey]: [
                                                ...prev[callType][configKey],
                                                {
                                                  [criteriaName]: [],
                                                },
                                              ],
                                              categoryWeight: {
                                                ...prev[callType][
                                                  'categoryWeight'
                                                ],
                                                [criteriaName]: 1,
                                              },
                                            },
                                          };
                                        });
                                        setCriteriaName('');
                                      }}
                                      fullWidth
                                    >
                                      Add new category
                                    </Button>
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </>
                        ) : (
                          <TextField
                            placeholder="JSON CONFIG"
                            variant="outlined"
                            name={'contentScoreConfig'}
                            fullWidth
                            size="small"
                            multiline
                            rows={17}
                            value={contentScoreConfigText[callType]}
                            onChange={(e) =>
                              setContentScoreConfigText((prev) => {
                                return {
                                  ...prev,
                                  [callType]: e.target.value,
                                };
                              })
                            }
                            type={'text'}
                          />
                        )}
                      </Box>
                    ) : null;
                  },
                )
              : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: 1,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                setShowEditContentScoringConfig(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setShowEditContentScoringConfig(false);
                if (configType === 'form') {
                  try {
                    setContentScoreConfigText((prev) => {
                      return {
                        ...prev,
                        outbound: JSON.stringify(
                          contentScoringConfig?.outbound || '{}',
                          null,
                          2,
                        ),
                        inbound: JSON.stringify(
                          contentScoringConfig?.inbound || '{}',
                          null,
                          2,
                        ),
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert('Failed to convert json to text. please try again.');
                  }
                } else {
                  try {
                    const outboundObj = JSON.parse(
                      contentScoreConfigText?.outbound || '{}',
                    );
                    const inboundObj = JSON.parse(
                      contentScoreConfigText?.inbound || '{}',
                    );
                    setContentScoringConfig((prev) => {
                      return {
                        ...prev,
                        outbound: outboundObj,
                        inbound: inboundObj,
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert(
                      'Failed to parse json text. please validate the json.',
                    );
                  }
                }
              }}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showUpdateFeatureMapping)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '70vw',
            height: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={1}
            borderBottom={'1px solid #d3d3d3'}
          >
            <Typography>Configure Feature Mapping</Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowUpdateFeatureMapping(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2} display={'flex'} gap={2} flexDirection={'column'}>
            {Object.keys(featureMapping || {})?.map((feature) => {
              return (
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Typography variant="body1">{feature}</Typography>
                    <TextField
                      label="Feature Title"
                      variant="outlined"
                      name={'title'}
                      size="small"
                      // fullWidth
                      sx={{
                        width: '60%',
                      }}
                      value={featureMapping?.[feature]?.title}
                      onChange={(e) =>
                        setFeatureMapping((prev) => {
                          return {
                            ...prev,
                            [feature]: {
                              ...prev[feature],
                              title: e.target.value,
                            },
                          };
                        })
                      }
                    />
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setFeatureMapping((prev) => {
                          return Object.keys(prev || {})
                            ?.filter((ftr) => ftr !== feature)
                            ?.reduce((acc, curr) => {
                              if (acc[curr] === undefined) {
                                acc[curr] = prev[curr];
                              }
                              return acc;
                            }, {});
                        });
                      }}
                    >
                      Remove
                    </Button> */}
                  </Box>
                  <Box ml={4}>
                    {Object.keys(
                      featureMapping?.[feature]?.subTitles || {},
                    )?.map((subFeature) => {
                      return (
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                          <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography variant="body1">
                              {subFeature}
                            </Typography>
                            <TextField
                              label="Secondary Feature Title"
                              variant="outlined"
                              name={'title'}
                              size="small"
                              // fullWidth
                              sx={{
                                width: '60%',
                              }}
                              value={
                                featureMapping?.[feature]?.subTitles?.[
                                  subFeature
                                ]?.title
                              }
                              onChange={(e) =>
                                setFeatureMapping((prev) => {
                                  return {
                                    ...prev,
                                    [feature]: {
                                      ...prev[feature],
                                      subTitles: {
                                        ...prev[feature].subTitles,
                                        [subFeature]: {
                                          title: e.target.value,
                                        },
                                      },
                                    },
                                  };
                                })
                              }
                            />
                            {/* <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setFeatureMapping((prev) => {
                                  return {
                                    ...prev,
                                    [feature]: {
                                      ...prev[feature],
                                      subTitles: Object.keys(
                                        prev?.[feature]?.subTitles || {},
                                      )
                                        ?.filter(
                                          (sbftr) => sbftr !== subFeature,
                                        )
                                        ?.reduce((acc, curr) => {
                                          if (acc[curr] === undefined) {
                                            acc[curr] = prev[curr];
                                          }
                                          return acc;
                                        }, {}),
                                    },
                                  };
                                });
                              }}
                            >
                              Remove
                            </Button> */}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            p={1}
            borderTop={'1px solid #d3d3d3'}
          >
            <Button
              variant="outlined"
              size="small"
              // startIcon={<Close />}
              onClick={() => setShowUpdateFeatureMapping(false)}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={showUpdateTrialDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Update Validity</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              minWidth: 300,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                Type
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="type-select-label"
                    id="type-select"
                    name="type"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    value={type}
                  >
                    <MenuItem value={'date'}>Valid Till</MenuItem>
                    <MenuItem value={'days'}>Valid For</MenuItem>
                    <MenuItem value={'end'}>End Validity</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {type !== 'end' ? (
                <>
                  <Grid item xs={4}>
                    {type === 'date' ? 'Date' : 'Days'}
                  </Grid>
                  <Grid item xs={8}>
                    {type === 'date' ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={date ? dayjs(new Date(date)) : null}
                          label="Date"
                          format="MM-DD-YYYY"
                          onChange={(date) => {
                            let date_ = new Date(date); // some mock date
                            // console.log(date);
                            var milliseconds = date_.getTime();
                            setDate(milliseconds);
                          }}
                        />
                      </LocalizationProvider>
                    ) : null}
                    {type === 'days' ? (
                      <TextField
                        label="Days"
                        variant="outlined"
                        name={'days'}
                        size="small"
                        // fullWidth
                        value={days}
                        onChange={(e) => setDays(e.target.value)}
                      />
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowUpdateTrialDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Dismiss
            </Button>
            <Button
              onClick={(e) => {
                setShowUpdateTrialDialog(false);
                dispatch(
                  updatePlanTrialEndsOn(
                    selectedOrganization,
                    {
                      trailEndsOn:
                        type === 'end'
                          ? new Date().getTime() - 172800000
                          : type === 'date'
                          ? date
                          : new Date().getTime() +
                            Number(days) * 24 * 60 * 60 * 1000,
                    },
                    () => {
                      dispatch(
                        getAllOrganizations(
                          [],
                          true,
                          'w_org',
                          15,
                          null,
                          () => {},
                        ),
                      );
                      alert('Information Updated!');
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default Customers;
