import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const updateFineTuningProject = (projectId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_FINE_TUNING_PROJECT' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(
            `/ads/mfnt/projects/${projectId}`,
            payload,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_FINE_TUNING_PROJECT_DONE',
              payload: {
                projectId: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(projectId);
            }
          } else {
            dispatch({
              type: 'UPDATING_FINE_TUNING_PROJECT_FAILED',
              payload: `Failed to update fine tuning project`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_FINE_TUNING_PROJECT_FAILED',
            payload: `Failed to update fine tuning project`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_FINE_TUNING_PROJECT_FAILED',
        payload: `Failed to update fine tuning project`,
      });
    }
  };
};

export default updateFineTuningProject;
