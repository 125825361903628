const pages = [
  {
    groupTitle: 'Main',
    id: 'main',
    pages: [
      {
        title: 'Home',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740',
      },
      {
        title: 'Invite Users',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/inviteUser',
      },
      {
        title: 'Whitelabel Customers',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/customers',
      },
      {
        title: 'Whitelabel Clients',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/clients',
      },
      {
        title: 'Organizations',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/org',
      },
      {
        title: 'Users',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/users',
      },
      {
        title: 'Practice Call Links',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-call-links',
      },
      {
        title: 'Prompts',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/prompts',
      },
      {
        title: 'Bots',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots',
      },
      {
        title: 'Preset Questions',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/preset-questions',
      },
      {
        title: 'Roleplays(Users)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls',
      },
      {
        title: 'Sales Call Analysis(Users)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis-users',
      },
      {
        title: 'Fine Tune Models',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/fine-tune-models',
      },
      {
        title: 'Privacy Policy & ToS',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/privacy-tos',
      },
      // {
      //   title: 'Conversation AI(Users)',
      //   href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/conversation-ai-users',
      // },
    ],
  },
  {
    groupTitle: 'Secondary',
    id: 'secondary',
    pages: [
      {
        title: 'Default Verticals & Designations',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/dvd',
      },
      // {
      //   title: 'Default Plans',
      //   href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/plans',
      // },
      {
        title: 'Plans & Add-ons',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/all-plans',
      },
      {
        title: 'Playbook Generation',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/playbook-generation',
      },
      {
        title: 'Roleplays(Admin)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls-admin',
      },
      {
        title: 'Sales Call Analysis(Admin)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis',
      },
      {
        title: 'Bots Requests',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots-requests',
      },
      {
        title: 'Demo Requests',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/demo-requests',
      },
      {
        title: 'Labelling Framework',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/labelling-framework',
      },
      {
        title: 'Test',
        href: '/test',
      },
      {
        title: 'AI Contest Creation Prompts',
        href:
          '/f550aeea-0bb4-41e9-b615-7a6cf101e740/create-contest-with-ai-prompts',
      },
      {
        title: 'Jobs',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/jobs',
      },
      {
        title: 'Contests',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/contests',
      },
      {
        title: 'Practice',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice',
      },
      {
        title: 'Create Contest',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/add-contest',
      },
      {
        title: 'Standard Data',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/standard-data',
      },
      {
        title: 'AWS transcribe poc',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/aws-transcribe-poc',
      },
      {
        title: 'Frontend Transcribe POC',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/fe-transcribe-poc',
      },
    ],
  },
];

export default pages;
