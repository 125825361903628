import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Chip, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const AutoCompleteMUI = ({
  id,
  multiple,
  value,
  onChange,
  onKeyDown,
  options,
  placeholder,
  label,
  onTextChange,
}) => {
  const theme = useTheme();

  return (
    <Autocomplete
      id={id}
      size="small"
      value={value}
      clearOnBlur
      onBlur={(event) => {
        onKeyDown(event);
      }}
      onKeyDown={(event) => {
        if (multiple) {
          if (event.key === 'Enter' || event.key === 'Tab') {
            // Prevent's default 'Enter' behavior.
            event.defaultMuiPrevented = true;
            event.preventDefault();
            // your handler code
            onKeyDown(event);
            setTimeout(() => {
              event.target.value = '';
            }, 10);
          }
        }
      }}
      onChange={onChange}
      freeSolo
      multiple={multiple || false}
      options={options}
      getOptionLabel={(option) => option || ''}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            size="small"
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) =>
        multiple ? (
          <TextField
            {...params}
            placeholder={placeholder}
            label={label}
            autoFocus
          />
        ) : (
          <TextField
            {...params}
            onChange={onTextChange}
            placeholder={placeholder}
            label={label}
            autoFocus
          />
        )
      }
      sx={{
        minWidth: 300,
      }}
    />
  );
};

AutoCompleteMUI.propTypes = {
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  onKeyDown: PropTypes.func,
  onTextChange: PropTypes.func,
};

export default AutoCompleteMUI;
