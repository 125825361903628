import uuid from 'draft-js/lib/uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const createFineTuningJob = (modelName, projectId, callback, errorCallback) => {
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.post(
          `/ads/mfnt/projects/${projectId}/fine-tune`,
          null,
          {
            params: {
              'request-id': uuid(),
              modelName,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-type': 'application/json',
            },
          },
        );
        // console.log(response);
        if (response.status === 200) {
          if (typeof callback === 'function') callback(response?.data);
        } else {
          if (typeof errorCallback === 'function')
            errorCallback(response?.data);
        }
      } catch (e) {
        console.log(e);
      }
      unsubscribe();
    });
  };
};

export default createFineTuningJob;
